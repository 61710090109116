import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Group, Menu, Text } from '@mantine/core'
import { type HistoryItem, useHistory } from '@/api/history'
import { Icon } from '@/components/lib/Icon'
import { useSearch } from '@/features/Search/hooks'
import { useSetStoredSplitSearch } from '@/stores/queries-store'
import { ROUTES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { HeaderMenuItem } from './HeaderMenuItem'

import styles from '../header.module.css'

export function HeaderLinkHistory() {
  const navigate = useNavigate()
  const { recentItems } = useHistory()
  const { doTextSearch } = useSearch()
  const setStoredSplitSearch = useSetStoredSplitSearch()
  const location = useLocation()
  const [historyOpen, setHistoryOpen] = useState(false)

  useEffect(() => setHistoryOpen(location.pathname === '/history'), [location])

  const handleSetQuery = (value: string) => {
    if (!value) {
      return
    }
    void doTextSearch(value)

    const params = new URLSearchParams({ search: value })
    const pathname = window.location.pathname.toLowerCase()

    setStoredSplitSearch(null)

    navigate(
      pathname.startsWith('/map')
        ? `/map?${params.toString()}`
        : `/overview?${params.toString()}`,
    )
  }

  const handleItemClick = (item: HistoryItem) => {
    captureEvent(POSTHOG_EVENTS.HISTORY.RERUN_SEARCH)
    void handleSetQuery(item.query)
  }

  return (
    <Menu
      trigger="hover"
      transitionProps={{ duration: 100 }}
      onOpen={() => setHistoryOpen(true)}
      onClose={() => setHistoryOpen(location.pathname === '/history')}
      offset={10}
      position="bottom-start"
    >
      <Menu.Target>
        <Group
          gap={5}
          h="2.5rem"
          wrap="nowrap"
          p="sm"
          className={styles.link}
          style={{
            cursor: 'pointer',
            borderBottom: historyOpen
              ? '6px solid var(--mantine-color-white)'
              : '6px solid #fff0',
            borderTop: '6px solid #fff0',
          }}
        >
          <Icon name="history" color="white" />
          <Text size="md" c="white">
            History
          </Text>
        </Group>
      </Menu.Target>
      <Menu.Dropdown
        bg="rgba(0, 0, 0, 0.733)"
        p="xs"
        lh="xs"
        mah={500}
        maw={400}
        styles={{
          dropdown: {
            border: 'none',
            backdropFilter: 'blur(1.5px)',
            overflowY: 'auto',
          },
        }}
      >
        <HeaderMenuItem
          key="all_history"
          pl="sm"
          onClick={() => {
            captureEvent(POSTHOG_EVENTS.HEADER.CLICKED, {
              headerItem: 'All History',
            })
            navigate(ROUTES.history)
          }}
        >
          All History
        </HeaderMenuItem>
        {recentItems?.length ? (
          recentItems.map((item) => (
            <HeaderMenuItem
              c="#c8cad3"
              key={crypto.randomUUID()}
              onClick={() => handleItemClick(item)}
            >
              {item.query}
            </HeaderMenuItem>
          ))
        ) : (
          <Text p="xs" size="xs" c="#a9adb9">
            Your saved searches will appear here
          </Text>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
