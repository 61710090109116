import { Slider, Stack, Text } from '@mantine/core'
import type { FC } from 'react'

type PostfilterCloudButtonProps = {
  currentCeil: number | null
  setCeil: (ceil: number) => void
}

export const PostfilterCloudButton: FC<PostfilterCloudButtonProps> = ({
  currentCeil,
  setCeil,
}) => {
  return (
    <Stack gap="xs">
      <Text size="s">Image Cloud Cover</Text>
      <Stack gap="xs">
        <Slider
          min={0}
          max={5}
          step={1}
          value={currentCeil || 5}
          onChange={(newValue) => newValue !== 0 && setCeil(newValue)}
          label={(v) => ['', '20%', '40%', '60%', '80%', 'All'][v]}
          marks={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]}
        />
      </Stack>
    </Stack>
  )
}
