import {
  useImageProvenanceDataMetadata,
  useSourceDataMetadata,
} from '@/api/data-metadata/use-data-metadata.ts'
import { MultiSelect } from '@/components/MultiSelect'
import { useSetSources, useSourcesFilter } from '@/stores/filters-store'

export function Sources() {
  const sourcesFilter = useSourcesFilter()
  const setSourcesFilter = useSetSources()
  const sourcesMap = useSourceDataMetadata()
  const { sources = [] } = useImageProvenanceDataMetadata()

  const sourceOptions = sources.map((source) => ({
    value: source,
    label: sourcesMap[source] ?? source,
  }))

  return (
    <MultiSelect
      value={sourcesFilter ?? []}
      onChange={setSourcesFilter}
      placeholder="Any"
      data={sourceOptions}
    />
  )
}
