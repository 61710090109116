import React from 'react'
import {
  ActionIcon,
  Button,
  Group,
  Select,
  Stack,
  Table,
  TextInput,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { TerrapinLocationType } from '@/features/AppLayout'
import type { FilterType, TerrapinLocation } from '@/hooks/use-terrapin'
import {
  getLocationType,
  getLocationValue,
  TerrapinLocationValidator,
} from './TerrapinLocation'
import { TerrapinStaticMapDisplay } from './TerrapinStaticMapDisplay'

import styles from './terrapin-location-panel.module.css'

export interface AreaOfInterestlocation {
  location: string
  type: TerrapinLocationType
  name: string
}

export interface TerrapinLocationRowProps {
  location: TerrapinLocation
  index: number
  onChange: (index: number, location: TerrapinLocation) => void
  onRemove: (index: number) => void
}

export function TerrapinLocationRow({
  location,
  index,
  onChange,
  onRemove,
}: TerrapinLocationRowProps) {
  const { name } = location
  const type = getLocationType(location)
  const value = getLocationValue(location)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false)

  if (!type) {
    return <></>
  }

  return (
    <Table.Tr className={styles.locationRow}>
      <Table.Td>
        <Select
          error={
            Object.values(TerrapinLocationType).includes(type)
              ? undefined
              : 'Unsupported type'
          }
          w={130}
          data={Object.values(TerrapinLocationType)}
          value={type}
          onChange={(value) =>
            onChange(index, {
              ...location,
              filters: location.filters.map((x) =>
                x.type === 'LOCATIONTYPE'
                  ? {
                      type: 'LOCATIONTYPE' as FilterType,
                      value: value as string,
                    }
                  : x,
              ),
            })
          }
          allowDeselect={false}
        />
      </Table.Td>
      <Table.Td>
        <TextInput
          value={value}
          flex={1}
          placeholder="Add a lat/long, polygon or MGRS"
          error={
            TerrapinLocationValidator[type]
              ? TerrapinLocationValidator[type](value)
              : true
          }
          onChange={(event) =>
            onChange(index, {
              ...location,
              filters: location.filters.map((x) =>
                x.type === 'LOCATION'
                  ? { type: 'LOCATION', value: event.currentTarget.value }
                  : x,
              ),
            })
          }
        />
      </Table.Td>
      <Table.Td>
        <TextInput
          value={name}
          placeholder="Add a name"
          flex={1}
          onChange={(event) =>
            onChange(index, {
              ...location,
              name: event.currentTarget.value,
            })
          }
        />
      </Table.Td>
      {showDeleteConfirmation ? (
        <Table.Td colSpan={2}>
          <Group gap="sm" align="top">
            <Button
              size="xs"
              variant="transparent"
              fw={400}
              p={0}
              color="#000000DE"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              size="xs"
              variant="transparent"
              fw={400}
              p={0}
              color="red"
              onClick={() => onRemove(index)}
            >
              Delete
            </Button>
          </Group>
        </Table.Td>
      ) : (
        <>
          <Table.Td w={70}>
            {TerrapinLocationValidator[type](value) ||
            value.trim().length === 0 ? (
              <Stack h={34.56} bg="#E0E0E0">
                <Icon style={{ margin: 'auto' }} name="warning_amber" />
              </Stack>
            ) : (
              <TerrapinStaticMapDisplay location={location} />
            )}
          </Table.Td>
          <Table.Td>
            <ActionIcon
              variant="white"
              color="black"
              mt={4}
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <Icon name="delete_outline" />
            </ActionIcon>
          </Table.Td>
        </>
      )}
    </Table.Tr>
  )
}
