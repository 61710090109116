import { Divider, Text } from '@mantine/core'
import { handleCollectionLocationDownload } from '@/api/collections'
import { useCollectionItems } from '@/api/collections/collection-items'
import type { Collection } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { openDeleteCollectionModal } from '@/features/AppLayout/Modals'
import {
  CollectionNavLink,
  ItemModalMenu,
} from '@/features/Collections/components'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES, ICONS } from '@/utils/constants'

export interface CollectionListItemModalMenuProps {
  data: Collection
  onClose: () => void
}

export function CollectionListItemModalMenu({
  data,
  onClose,
}: CollectionListItemModalMenuProps) {
  const { id, name } = data
  const { totalCount, filter } = useCollectionItems(id)
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  const onClickDelete = () => {
    openDeleteCollectionModal(data.id)
  }
  const onClickDownload = () => {
    void handleCollectionLocationDownload({
      collectionId: data.id,
      collectionName: data.name,
      totalCount,
      filter,
    })
  }

  const icons = {
    delete: <Icon name={ICONS.delete} />,
    download: <Icon name={ICONS.download} />,
  }

  const label = (value: string) => (
    <Text c="#0000099" size="xs" style={{ letterSpacing: 1 }}>
      {value}
    </Text>
  )

  return (
    <ItemModalMenu
      name={name}
      onClose={onClose}
      content={
        <>
          {label('MANAGE SAVED SEARCH')}
          <CollectionNavLink
            icon={icons.delete}
            label="Delete Saved Search"
            pb={0}
            c="#D32F2F"
            onClick={onClickDelete}
          />

          <Divider my="sm" />

          {label('ACTIONS')}
          {!isCommercial && (
            <CollectionNavLink
              icon={icons.download}
              label="Download Location Data"
              c="#000000DE"
              onClick={onClickDownload}
            />
          )}
        </>
      }
    />
  )
}
