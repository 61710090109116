import type { DefaultMantineColor } from '@mantine/core'

export const avatarColorOptions = [
  'teal.4',
  'blue.4',
  'teal',
  'blue',
  'purple',
  'red.5',
  'red.4',
  'red.3',
  'yellow',
  'orange',
]

export const COLORS: Partial<
  Record<
    DefaultMantineColor,
    [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
    ]
  >
> = {
  dark: [
    '#c1c2c5',
    '#a9aaad',
    '#8e8f94',
    '#6f7075', // Dark Mode
    '#4d4e53',
    '#2b2c30',
    '#1b1c1e', // Light Mode
    '#0f1011',
    '#070808',
    '#000000',
  ],
  blue: [
    '#b3d1f3',
    '#99b2e6',
    '#7f93da',
    '#6574cd', // Secondary
    '#4b55c1',
    '#3136b4',
    '#0091ea', // Primary
    '#002d9c',
    '#00248f',
    '#001b82',
  ],
  gray: [
    '#f7fafc',
    '#edf2f7',
    '#e0e0e0',
    '#cbd5e0', // Dark Mode
    '#a0aec0',
    '#718096',
    '#4a5568', // Light Mode
    '#2d3748',
    '#1a202c',
    '#171923',
  ],
  red: [
    '#fff5f5',
    '#fed7d7',
    '#feb2b2',
    '#fc8181', // Dark Mode
    '#f56565',
    '#e53e3e',
    '#c53030', // Light Mode
    '#9b2c2c',
    '#822727',
    '#63171B',
  ],
}
