import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLastAttemptedRoute } from '@/features/AppRouting/hooks'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { useHasActiveSubscription } from '@/hooks/use-has-active-subscription.ts'

export function AccessDenied() {
  const { refreshUser, isAuthorizedUser, isLoading } = useDantiAuth()
  const { hasActiveSubscription } = useHasActiveSubscription()
  const [lastAttemptedRoute, setLastAttemptedRoute] = useLastAttemptedRoute()
  const navigate = useNavigate()

  useEffect(() => {
    async function handleRefreshUser() {
      await refreshUser()

      if (isAuthorizedUser() && !isLoading && hasActiveSubscription) {
        const temporaryLastAttemptedRoute = lastAttemptedRoute ?? '/'
        setLastAttemptedRoute(null)
        navigate(temporaryLastAttemptedRoute)
      }
    }

    void handleRefreshUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <h1>Access Denied</h1>
}
