import { getPermitDescription, getSortedPermits } from '@/api/results'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { AttomPermitReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components'
import { useLocalNavigation } from '@/features/SingleObjectView/hooks'
import {
  ICONS,
  RESULT_CATEGORIES,
  RESULT_CATEGORY_COLORS,
} from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type {
  AttomPermit,
  AttomResult,
  ResultWithId,
} from '@/utils/types/result-types'

export function AttomObjectView({ item: rawItem }: { item: ResultWithId }) {
  const item = rawItem as AttomResult
  const permits = getSortedPermits(item)

  const { toPrevious, toNext, current, selectItem } =
    useLocalNavigation<AttomPermit>(permits)

  // Return simplified template if no permits
  if (permits.length === 0) {
    return (
      <SingleObjectViewTemplate<AttomResult, AttomPermit>
        item={item}
        hideActions={HIDE_ACTIONS_BY_CATEGORY[RESULT_CATEGORIES.ATTOM]}
        hero={{
          details: {
            iconName: ICONS.construction,
            color: RESULT_CATEGORY_COLORS.attom,
            title: item.title,
            leftDetail: 'No permits found',
            leftDetailsColumn: [`Address: ${item.properties.address}`],
          },
          metaDetails: [formatDatetime(item.authoredOn)],
        }}
        content={{
          title: `Summary of Permits and Hazards for ${item.properties.address}`,
        }}
      />
    )
  }

  return (
    <SingleObjectViewTemplate<AttomResult, AttomPermit>
      {...{
        item,
        hideActions: HIDE_ACTIONS_BY_CATEGORY[RESULT_CATEGORIES.ATTOM],
        hero: {
          details: {
            iconName: ICONS.construction,
            color: RESULT_CATEGORY_COLORS.attom,
            title: item.title,
            leftDetail: current?.permitNumber ?? 'No permit number',
            rightDetail: current?.effectiveDate ?? '--',
            leftDetailsColumn: [
              getPermitDescription(current ?? ({} as AttomPermit)),
              `Business Name: ${current?.businessName ?? '--'}`,
              `Status: ${current?.status ?? '--'}`,
            ],
            rightDetailsColumn: ['Owner', current?.homeOwnerName ?? '--'],
          },
          metaDetails: [formatDatetime(item.authoredOn)],
          toNext,
          toPrevious,
        },

        content: {
          title: `Summary of Permits and Hazards for ${item.properties.address}`,
        },

        carousel: {
          title: 'All Permits',
          items: permits,
          renderCard: (permit: AttomPermit) => (
            <AttomPermitReferenceCard result={permit} />
          ),
          selectItem,
          grid: true,
        },
      }}
    />
  )
}
