import { Center, Paper, type PaperProps } from '@mantine/core'
import { DantiLogoMark } from '@/components/lib/DantiLogoMark.tsx'

import styles from './skeleton.module.css'

interface SkeletonProps extends PaperProps {
  showDanti?: true
  style?: React.CSSProperties
}

export function Skeleton({ showDanti, style, ...props }: SkeletonProps) {
  return (
    <Paper
      radius="sm"
      pos="relative"
      style={{ overflow: 'hidden', height: '100%', width: '100%', ...style }}
      {...props}
    >
      <div className={styles['shimmer']} />

      <Center h="100%">
        <Center inline pos="relative">
          {showDanti && (
            <DantiLogoMark
              width="100%"
              color="#c4c4c4"
              style={{ margin: '35%' }}
            />
          )}
        </Center>
      </Center>
    </Paper>
  )
}
