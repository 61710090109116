import { getSearchRequest } from '@/api/danti-requests'
import type {
  DataMetadataImageProvenanceResponse,
  DataMetadataResponse,
  ImageProvenanceMetadata,
  // ImageProvenanceRelation,
} from './types'

// function convertRelationsToArray(
//   relations: Partial<ImageProvenanceRelation>,
//   type: keyof ImageProvenanceRelation,
// ): Omit<Record<keyof ImageProvenanceRelation, string[]>, typeof type> {
//   const initialRelations: ImageProvenanceRelation = {
//     source: relations.source ?? new Set<string>(),
//     provider: relations.provider ?? new Set<string>(),
//     platform: relations.platform ?? new Set<string>(),
//     sensor: relations.sensor ?? new Set<string>(),
//   }
//   delete initialRelations[type]
//   return Object.fromEntries(
//     Object.entries(initialRelations).map(([key, value]) => [key, [...value]]),
//   )
// }

function formatImageProvenance(
  imageProvenance: DataMetadataImageProvenanceResponse[],
): ImageProvenanceMetadata {
  const sources = new Set<string>()
  const providers = new Set<string>()
  const platforms = new Set<string>()
  const sensors = new Set<string>()

  // const sourceRelations = {} as Record<
  //   string,
  //   Omit<ImageProvenanceRelation, 'source'>
  // >
  // const providerRelations = {} as Record<
  //   string,
  //   Omit<ImageProvenanceRelation, 'provider'>
  // >
  // const platformRelations = {} as Record<
  //   string,
  //   Omit<ImageProvenanceRelation, 'platform'>
  // >
  // const sensorRelations = {} as Record<
  //   string,
  //   Omit<ImageProvenanceRelation, 'sensor'>
  // >

  imageProvenance.forEach((image) => {
    sources.add(image.source)
    providers.add(image.provider)
    if (image.platform) {
      platforms.add(image.platform)
    }
    sensors.add(image.sensor)

    // if (sourceRelations[image.source]) {
    //   sourceRelations[image.source].provider.add(image.provider)
    //   if (image.platform) {
    //     sourceRelations[image.source].platform.add(image.platform)
    //   }
    //   sourceRelations[image.source].sensor.add(image.sensor)
    // } else {
    //   sourceRelations[image.source] = {
    //     provider: new Set([image.provider]),
    //     platform: new Set(image.platform ? [image.platform] : []),
    //     sensor: new Set([image.sensor]),
    //   }
    // }
    //
    // if (providerRelations[image.provider]) {
    //   providerRelations[image.provider].source.add(image.source)
    //   if (image.platform) {
    //     providerRelations[image.provider].platform.add(image.platform)
    //   }
    //   providerRelations[image.provider].sensor.add(image.sensor)
    // } else {
    //   providerRelations[image.provider] = {
    //     source: new Set([image.source]),
    //     platform: new Set(image.platform ? [image.platform] : []),
    //     sensor: new Set([image.sensor]),
    //   }
    // }
    //
    // if (image.platform) {
    //   if (platformRelations[image.platform]) {
    //     platformRelations[image.platform].source.add(image.source)
    //     platformRelations[image.platform].provider.add(image.provider)
    //     platformRelations[image.platform].sensor.add(image.sensor)
    //   } else {
    //     platformRelations[image.platform] = {
    //       source: new Set([image.source]),
    //       provider: new Set([image.provider]),
    //       sensor: new Set([image.sensor]),
    //     }
    //   }
    // }
    //
    // if (sensorRelations[image.sensor]) {
    //   sensorRelations[image.sensor].source.add(image.source)
    //   sensorRelations[image.sensor].provider.add(image.provider)
    //   if (image.platform) {
    //     sensorRelations[image.sensor].platform.add(image.platform)
    //   }
    // } else {
    //   sensorRelations[image.sensor] = {
    //     source: new Set([image.source]),
    //     provider: new Set([image.provider]),
    //     platform: new Set(image.platform ? [image.platform] : []),
    //   }
    // }
  })

  return {
    sources: [...sources],
    providers: [...providers],
    platforms: [...platforms],
    sensors: [...sensors],
    // relations: {
    //   source: convertRelationsToArray(sourceRelations, 'source'),
    //   provider: convertRelationsToArray(providerRelations, 'provider'),
    //   platform: convertRelationsToArray(platformRelations, 'platform'),
    //   sensor: convertRelationsToArray(sensorRelations, 'sensor'),
    // },
  }
}

export async function getDataMetadata() {
  const response = await getSearchRequest<DataMetadataResponse>('v2/metadata')
  return {
    ...response.data,
    imageProvenance: formatImageProvenance(response.data.imageProvenance),
  }
}
