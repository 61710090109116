import React, { type PropsWithChildren } from 'react'
import { Box, Button, Collapse, Flex, Grid, Group, Text } from '@mantine/core'
import {
  CameraAngle,
  CloudCover,
  Orientation,
  Platforms,
  ProvidersStatic,
  Quality,
  Sensors,
  Sources,
  Timeframe,
} from '@/features/Filters'
import { useSearch } from '@/features/Search/hooks'
import {
  useHasDefaultFilterValues,
  useIsFilterExpanded,
  useResetFilters,
  useSetTimePreset,
  useTimePresetFilter,
  useToggleFilterPanel,
} from '@/stores/filters-store'

function FilterSubPanel({
  children,
  title,
  span,
  id,
}: PropsWithChildren<{ title: string; span: number; id: string }>) {
  return (
    <Grid.Col span={span} id={id}>
      <Box bg="#fff" p="sm" style={{ height: '100%' }}>
        <Text size="xs" mt="-0.5rem" mb="xs">
          {title}
        </Text>
        {children}
      </Box>
    </Grid.Col>
  )
}

export function FilterPanel() {
  const resetFilters = useResetFilters()
  const { doLastSearchWithFilters } = useSearch()
  const hasDefaultValues = useHasDefaultFilterValues()
  const isOpen = useIsFilterExpanded()
  const toggle = useToggleFilterPanel()
  const setTimePreset = useSetTimePreset()
  const timePreset = useTimePresetFilter()

  return (
    <Collapse in={isOpen}>
      <Box bg="gray.1" p="sm" c="gray">
        <Grid columns={4} gutter="sm" m={0}>
          <Grid.Col span={2}>
            <Grid columns={2} gutter="sm">
              <FilterSubPanel title="Timeframe" span={2} id="timeframe">
                <Timeframe
                  selectedTimePreset={timePreset}
                  onSelect={setTimePreset}
                  disabled={false}
                />
              </FilterSubPanel>
              <FilterSubPanel title="Sensor Types" span={2} id="sensorTypes">
                <Sensors />
              </FilterSubPanel>
              <FilterSubPanel title="Providers" span={1} id="providers">
                <ProvidersStatic />
              </FilterSubPanel>
              <FilterSubPanel title="Sources" span={1} id="sources">
                <Sources />
              </FilterSubPanel>
            </Grid>
          </Grid.Col>
          <FilterSubPanel title="Quality" span={1} id="quality">
            <Quality />
          </FilterSubPanel>
          <FilterSubPanel title="Orientation" span={1} id="orientation">
            <Orientation />
          </FilterSubPanel>
          <FilterSubPanel title="Platforms" span={1} id="platforms">
            <Platforms />
          </FilterSubPanel>
          <FilterSubPanel title="Camera Angle" span={1} id="cameraAngle">
            <CameraAngle />
          </FilterSubPanel>
          <FilterSubPanel title="Cloud Cover" span={2} id="cloudCover">
            <CloudCover />
          </FilterSubPanel>
        </Grid>
        <Flex>
          <Group mt="xs" ml="auto">
            <Button
              size="xs"
              fw="normal"
              tt="uppercase"
              disabled={hasDefaultValues()}
              variant="subtle"
              color="gray"
              onClick={() => resetFilters()}
            >
              Clear
            </Button>
            <Button
              size="xs"
              fw="normal"
              tt="uppercase"
              variant="filled"
              onClick={() => {
                void doLastSearchWithFilters()
                toggle()
              }}
            >
              Apply
            </Button>
          </Group>
        </Flex>
      </Box>
    </Collapse>
  )
}

export const MemoFilterPanel = React.memo(FilterPanel)
