import {
  useImageProvenanceDataMetadata,
  useProviderDataMetadata,
} from '@/api/data-metadata/use-data-metadata.ts'
import { MultiSelect } from '@/components/MultiSelect'
import { useProvidersFilter, useSetProviders } from '@/stores/filters-store'

export function ProvidersStatic() {
  const providersFilter = useProvidersFilter()
  const setProvidersFilter = useSetProviders()
  const providersMap = useProviderDataMetadata()
  const { providers = [] } = useImageProvenanceDataMetadata()

  const providerOptions = providers.map((provider) => ({
    value: provider,
    label: providersMap[provider] ?? provider,
  }))

  return (
    <MultiSelect
      value={providersFilter ?? []}
      onChange={setProvidersFilter}
      placeholder="Any"
      data={providerOptions}
    />
  )
}
