import { Slider, Stack, Text } from '@mantine/core'
import type { FC } from 'react'

type PostfilterQualityButtonProps = {
  currentCeil: number | null
  setCeil: (ceil: number) => void
}

export const PostfilterQualityButton: FC<PostfilterQualityButtonProps> = ({
  currentCeil,
  setCeil,
}) => {
  return (
    <Stack gap="xs">
      <Text size="s">Image Quality</Text>
      <Stack gap="xs">
        <Slider
          min={0}
          max={4}
          step={1}
          value={currentCeil || 4}
          onChange={(endValue) => {
            if (endValue !== 0) {
              setCeil(endValue)
            }
          }}
          label={(v) =>
            ({
              1: 'Best (50cm)',
              2: 'Great (1m)',
              3: 'Standard (3m)',
              4: 'All',
            })[v]
          }
          marks={[{ value: 1 }, { value: 2 }, { value: 3 }]}
        />
      </Stack>
    </Stack>
  )
}
