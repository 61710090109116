import { Chip, Group } from '@mantine/core'
import {
  useImageProvenanceDataMetadata,
  useSensorDataMetadata,
} from '@/api/data-metadata/use-data-metadata.ts'
import { Icon } from '@/components/lib/Icon.tsx'
import { useSensorsFilter, useSetSensors } from '@/stores/filters-store'

export function Sensors() {
  const sensorsFilter = useSensorsFilter()
  const setSensors = useSetSensors()
  const sensorsMap = useSensorDataMetadata()
  const { sensors = [] } = useImageProvenanceDataMetadata()

  const sensorOptions = sensors.map((sensor) => ({
    value: sensor,
    label: sensorsMap[sensor] ?? sensor,
  }))

  return (
    <Chip.Group
      multiple
      value={sensorsFilter}
      onChange={(values) => setSensors(values)}
    >
      <Group gap="xs">
        {sensorOptions.map(({ value, label }) => (
          <Chip
            key={value}
            icon={<Icon name="check_small" size="xs" />}
            variant={sensorsFilter.includes(value) ? 'filled' : 'outline'}
            value={value}
            size="xs"
          >
            {label}
          </Chip>
        ))}
      </Group>
    </Chip.Group>
  )
}
