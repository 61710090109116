import type { CardProps } from '@mantine/core'
import { getSortedRiskKeys } from '@/api/results'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES, SCORE_COLORS } from '@/utils/constants'
import { RISK_ICONS } from '@/utils/svg-icons'
import type { HazardRiskResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.HAZARD_RISK
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface HazardRiskBentoCardProps extends CardProps {
  result: HazardRiskResult
  subEntryIndex: number
  size: string
}

export const HazardRiskBentoCard = ({
  result,
  subEntryIndex,
  ...props
}: HazardRiskBentoCardProps) => {
  const riskKeys = getSortedRiskKeys(result)
  const riskKey = riskKeys[subEntryIndex]
  const risk = result.properties.risks[riskKey] as {
    title: string
    score: string
  }

  return (
    <BentoCard
      {...props}
      result={result}
      index={subEntryIndex}
      hideActions={actionsToHide}
      resultCategory={category}
      svgIcon={RISK_ICONS[riskKey]}
      content={{
        heading: 'Risks',
        subHeading: risk.title,
        color: SCORE_COLORS[risk.score],
      }}
      totalResults={riskKeys.length}
    />
  )
}

export default HazardRiskBentoCard
