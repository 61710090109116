import { useMapDrawStore } from '@/stores/map-draw-store/store'

export const useIsDrawing = () => useMapDrawStore((s) => s.isDrawing)
export const useDrawMode = () => useMapDrawStore((s) => s.drawMode)
export const useSetDrawMode = () => useMapDrawStore((s) => s.setDrawMode)
export const useCurrentSearchGeometry = () =>
  useMapDrawStore((s) => s.currentSearchGeometry)
export const useLastSearchPolygon = () =>
  useMapDrawStore((s) => s.lastSearchPolygon)
export const useUploadedFeature = () =>
  useMapDrawStore((s) => s.uploadedFeature)
export const useSearchHereMarker = () =>
  useMapDrawStore((s) => s.searchHereMarker)
export const useDrawController = () => useMapDrawStore((s) => s.drawController)

export const useSetCurrentSearchGeometry = () =>
  useMapDrawStore((s) => s.setCurrentSearchGeometry)
export const useSetDrawController = () =>
  useMapDrawStore((s) => s.setDrawController)
export const useSetIsDrawing = () => useMapDrawStore((s) => s.setIsDrawing)
export const useSetLastSearchPolygon = () =>
  useMapDrawStore((s) => s.setLastSearchPolygon)
export const useSetSearchHereMarker = () =>
  useMapDrawStore((s) => s.setSearchHereMarker)
export const useSetUploadedFeature = () =>
  useMapDrawStore((s) => s.setUploadedFeature)
