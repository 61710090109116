import { getFireDataFields } from '@/api/results'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { FireReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useResultNavigation } from '@/features/SingleObjectView/hooks'
import { RESULT_CATEGORIES, RESULT_CATEGORY_COLORS } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { FireResult, ResultWithId } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.FIRE
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function FireObjectView({ item: rawItem }: { item: ResultWithId }) {
  const item = rawItem as FireResult

  const { selectItem, items, toNext, toPrevious } =
    useResultNavigation<FireResult>(item)

  const color = RESULT_CATEGORY_COLORS.fire.toLowerCase().slice(1)

  return (
    <SingleObjectViewTemplate<FireResult, FireResult>
      {...{
        item,
        hideActions,
        hero: {
          geometry: item.geometry,
          color,
          metaDetails: [formatDatetime(item.authoredOn)],
          toNext,
          toPrevious,
        },
        content: {
          title: 'Wildfire',
          data: getFireDataFields(item),
        },
        carousel: {
          items,
          title: 'All Wildfires',
          renderCard: (fire: FireResult) => <FireReferenceCard result={fire} />,
          selectItem,
          grid: true,
        },
      }}
    />
  )
}
