import type {
  Filters,
  FilterValues,
  UIFilterValues,
} from '@/stores/filters-store/types'
import { createStore } from '@/utils/create-store'
import { hourlyFrequency } from '@/utils/format-datetime'
import { type DateValue, TimePreset } from '@/utils/types/filter-types'
import { isEqual } from 'lodash'

export const initialUIFilterState: UIFilterValues = {
  timePreset: TimePreset.None,
  customTimeStart: null,
  customTimeEnd: null,
  sensors: [],
  sources: [],
  providers: [],
  platforms: [],
  categories: [],
  quality: '5000',
  cloudCover: 100,
  angle: '50',
  orientations: [],
}

export const initialFilterState: FilterValues = {
  ...initialUIFilterState,
  formattedAddress: '',
  placeId: '',
  location: '',
  locationType: '',
  radius: null,
  keywords: [],
  isFilterExpanded: false,
  frequency: hourlyFrequency(7),
}

export const useFiltersStore = createStore<Filters>({
  initialState: initialFilterState,
  actions: (set, get): Partial<Filters> => ({
    setFilters: (filters: Partial<Filters>) => {
      set(filters)
    },
    hasDefaultValues: () => {
      const filtersState = get()
      return Object.keys(initialUIFilterState).every((key) => {
        const keyName = key as keyof UIFilterValues
        return isEqual(filtersState[keyName], initialUIFilterState[keyName])
      })
    },
    setTimePreset: (
      timePreset: TimePreset,
      start: DateValue,
      end: DateValue,
    ) => {
      set({
        timePreset,
        customTimeStart: start,
        customTimeEnd: end,
      })
    },
    toggleSensors: (sensor: string) => {
      const currentSensors = new Set(get().sensors)
      if (currentSensors.has(sensor)) {
        currentSensors.delete(sensor)
      } else {
        currentSensors.add(sensor)
      }
      set({ sensors: [...currentSensors] })
    },
    setSensors: (sensors: string[]) => {
      set({ sensors })
    },
    setSources: (sources: string[]) => {
      set({ sources })
    },
    setProviders: (providers: string[]) => {
      set({ providers })
    },
    setPlatforms: (platforms: string[]) => {
      set({ platforms })
    },
    setCategories: (categories: string[]) => {
      set({ categories })
    },
    setLocation: (location: string, locationType: string) => {
      set({ location, locationType })
    },
    setRadius: (geojson) => set({ radius: geojson }),
    resetFilters: () => {
      const { isFilterExpanded } = get()
      // Don't reset the expanded state
      set({ ...initialFilterState, isFilterExpanded })
    },
    toggleFilterPanel: () => {
      const currentState = get()
      set({ isFilterExpanded: !currentState.isFilterExpanded })
    },
    setEmailUpdateFrequency: (frequency: string) => {
      set({ frequency: frequency })
    },
  }),
})
