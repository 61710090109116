import React from 'react'
import { Button, Group, Stack, Text, TextInput, Title } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useHistory } from '@/api/history'
import { Icon } from '@/components/lib/Icon'
import { Pager } from '@/components/pagination'
import { openRemoveAllHistoryModal } from '@/features/AppLayout/Modals'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'

const handleRemoveAll = () => {
  captureEvent(POSTHOG_EVENTS.HISTORY.REMOVE_ALL)
  openRemoveAllHistoryModal()
}

export function HistoryDetailHeader() {
  const [searchText, setSearchText] = React.useState('')
  const [debouncedSearchText] = useDebouncedValue(searchText, 300)

  const { search, currentPage, pageSize, totalCount, nextPage, previousPage } =
    useHistory()

  React.useEffect(() => {
    if (debouncedSearchText !== '') {
      captureEvent(POSTHOG_EVENTS.HISTORY.SEARCH)
    }
    search(debouncedSearchText)
  }, [search, debouncedSearchText])

  return (
    <Stack w="100%" gap={12}>
      <Title c="#424242" size={60}>
        History
      </Title>
      <Group gap="md">
        <TextInput
          leftSectionPointerEvents="none"
          leftSection={<Icon name="search" />}
          placeholder="Search in history"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          flex={1}
          miw={200}
        />
        <Button
          variant="transparent"
          color="red"
          justify="start"
          style={{ padding: 0 }}
          leftSection={<Icon size={18} name="delete_outline" />}
          onClick={handleRemoveAll}
        >
          <Text size="xs" style={{ fontWeight: 'bold' }}>
            DELETE ALL HISTORY
          </Text>
        </Button>
        <Pager
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      </Group>
    </Stack>
  )
}
