import { RESULT_CATEGORIES } from '@/utils/constants'
import type {
  ImageryResult,
  PropertyResult,
  RawAnalyticResult,
  RawAttomResult,
  RawCoreLogicPropertyResult,
  RawCoreLogicRiskResult,
  RawHazardPropertyResult,
  RawHazardRiskResult,
  RawPublicationResult,
  RawRelatedImageryResult,
  ResultWithId,
} from '@/utils/types/raw-result-types.ts'
import type {
  AttomResult,
  CoreLogicPropertyResult,
  CoreLogicRiskResult,
  HazardPropertyResult,
  HazardRiskResult,
  PublicationResult,
  RegridResult,
  VexcelImage,
  VexcelResult,
} from '@/utils/types/result-types.ts'

export const loadGenericProps = (data: ResultWithId) => ({
  _dId: data.properties._dId,
  _dCategory: data.properties._dCategory,
  _dProvider: data.properties._dProvider,
  datetime: data.properties.datetime,
})

export const consumeRegridResult = (data: PropertyResult): RegridResult => {
  const { properties } = data as unknown as {
    properties: {
      _dAddress: string
      _dCategory: string
      _dProvider: string
      datetime: string
      address: string
      resultCategory: string
      fields?: {
        ll_gisacre: number
        ll_gissqft: number
        ll_updated_at: string
        mail_city: string
        geoid: string
        county: string
        parcelnumb: string
        legaldesc: string
      }
      vexcel_images?: { vexcel_images: VexcelImage[] }
    }
  }

  const regridResult: RegridResult = {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.REGRID,
      lotSizeAcres: properties.fields?.ll_gisacre,
      lotSizeSquareFeet: properties.fields?.ll_gissqft,
      address: properties._dAddress,
      city: properties.fields?.mail_city,
      geoid: properties.fields?.geoid,
      county: properties.fields?.county,
      parcelNumber: properties.fields?.parcelnumb,
      legalDescription: properties.fields?.legaldesc,
      updatedAt: properties.fields?.ll_updated_at,
    },
  }

  return regridResult
}

export const consumeRegridVexcelResult = (
  data: PropertyResult,
): VexcelResult => {
  const { properties } = data as unknown as {
    properties: {
      vexcel_images?: { vexcel_images: VexcelImage[] }
    }
  }

  const vexcelImages = properties.vexcel_images?.vexcel_images || []
  const firstImage = vexcelImages[0] || {}

  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.VEXCEL,
      collection: firstImage.collection,
      'estimate-date': firstImage['estimate-date'],
      url: firstImage.url,
      // eslint-disable-next-line camelcase
      vexcel_images: { vexcel_images: vexcelImages },
    },
  }
}

const consumeAttomResult = (data: RawAttomResult): AttomResult => {
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.ATTOM,
      updatedAt: data.properties.updatedAt,
      address: data.properties.address.oneLine,
      buildingPermits: (
        data.properties.buildingPermits?.buildingPermits || []
      ).map((permit) => ({
        businessName: permit.businessName,
        description: permit.description,
        effectiveDate: permit.effectiveDate,
        homeOwnerName: permit.homeOwnerName,
        jobValue: permit.jobValue,
        permitNumber: permit.permitNumber,
        status: permit.status,
        type: permit.type,
      })),
    },
  }
}

const consumeHazardPropertyResult = (
  data: RawHazardPropertyResult,
): HazardPropertyResult => {
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.HAZARD_PROPERTY,
      datetime: data.properties.datetime,
      address: data.properties.property.address,
      useCode: data.properties.property.use_code,
      bathsTotal:
        data.properties.mls_listing_record_details?.rm_baths_total ??
        data.properties.assessment.Number_of_Baths,
      bedroomsTotal:
        data.properties.mls_listing_record_details?.rm_bedrooms_total ??
        data.properties.assessment.Number_of_Bedrooms,
      yearBuilt: data.properties.mls_listing_record_details?.in_year_built,
      bathsPartial:
        data.properties.mls_listing_record_details?.rm_baths_partial,
      coolingFeatures:
        data.properties.mls_listing_record_details?.if_cooling_features ??
        data.properties.assessment.Air_Conditioning,
      publicRemarks:
        data.properties.mls_listing_record_details?.in_public_remarks,
      images: data.properties.images?.images || [],
      listDate: data.properties.listing_record?.list_date,
      listPrice: data.properties.listing_record?.list_price,
      listStatus: data.properties.listing_record?.status,
    },
  }
}
const consumeHazardRiskResult = (
  data: RawHazardRiskResult,
): HazardRiskResult => {
  const { properties } = data
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.HAZARD_RISK,
      datetime: properties.datetime,
      address: properties.address,
      risks: properties.danti_highlights,
    },
  }
}

const consumeCoreLogicRiskResult = (
  data: RawCoreLogicRiskResult,
): CoreLogicRiskResult => {
  const baseProps = loadGenericProps(data)
  return {
    ...data,
    properties: {
      ...baseProps,
      resultCategory: RESULT_CATEGORIES.CORELOGIC_RISK,
      datetime: data.properties.datetime,
      address: data.properties._dAddress,
      crimeRisk: data.properties.crimeRisk,
      earthquakeRisk: data.properties.earthquakeRisk,
      floodRisk: data.properties.floodRisk,
      hailRisk: data.properties.hailRisk,
      coastalStormRisk: data.properties.coastalStormRisk,
    } as const,
  } as CoreLogicRiskResult
}

const consumeCoreLogicPropertyResult = (
  data: RawCoreLogicPropertyResult,
): CoreLogicPropertyResult => {
  const baseProps = loadGenericProps(data)
  return {
    ...data,
    properties: {
      ...baseProps,
      resultCategory: RESULT_CATEGORIES.CORELOGIC_PROPERTY,
      datetime: data.properties.datetime,
      address: data.properties._dAddress,
      addressMatchInformation: data.properties.addressMatchInformation,
      buildingCharacteristics: data.properties.buildingCharacteristics,
      buildingFeatures: data.properties.buildingFeatures,
      clip: data.properties.clip,
      coastalDistance: data.properties.coastalDistance,
      distanceToFirestation: data.properties.distanceToFirestation,
      distanceToShore: data.properties.distanceToShore,
      foundationType: data.properties.foundationType,
      involuntaryLiens: data.properties.involuntaryLiens,
      propertyAPN: data.properties.propertyAPN,
      propertyAddress: data.properties.propertyAddress,
      propertyDetail: data.properties.propertyDetail,
      roofAge: data.properties.roofAge,
      v1PropertyId: data.properties.v1PropertyId,
      voluntaryLiens: data.properties.voluntaryLiens,
    } as const,
  } as CoreLogicPropertyResult
}

export const consumePropertyResult = (data: PropertyResult): ResultWithId => {
  if (data.properties._dProvider === 'VEXCEL') {
    return consumeRegridVexcelResult(data)
  }
  if (data.properties._dProvider === 'REGRID') {
    return consumeRegridResult(data)
  }
  if (data.properties._dProvider === 'ATTOM') {
    return consumeAttomResult(data as RawAttomResult)
  }
  if (data.properties._dProvider === 'CORELOGIC' && data.properties.crimeRisk) {
    return consumeCoreLogicRiskResult(data as RawCoreLogicRiskResult)
  }
  if (
    data.properties._dProvider === 'CORELOGIC' &&
    data.properties.buildingCharacteristics
  ) {
    return consumeCoreLogicPropertyResult(data as RawCoreLogicPropertyResult)
  }
  if (
    data.properties._dProvider === 'HAZARD' &&
    'property' in data.properties
  ) {
    return consumeHazardPropertyResult(data as RawHazardPropertyResult)
  }
  return consumeHazardRiskResult(data as RawHazardRiskResult)
}

export const consumeImageResult = (
  data: ImageryResult | RawAnalyticResult | RawRelatedImageryResult,
) => ({
  ...data,
  properties: {
    ...data.properties,
    _dQuality: data.properties._dQuality,
    _dCloudCover: data.properties._dCloudCover,
    _dCollect: data.properties._dCollect,
    _dSensor: data.properties._dSensor,
    featureId: data.properties.featureId,
  },
})

export const consumePublicationResult = (data: RawPublicationResult) => {
  const embedded = data.properties._dEmbedded || []
  return {
    ...data,
    properties: { ...data.properties, _dEmbedded: embedded },
  } as PublicationResult
}

export const ingestTransforms = {
  [RESULT_CATEGORIES.PROPERTY]: consumePropertyResult,
  [RESULT_CATEGORIES.IMAGE]: consumeImageResult,
  [RESULT_CATEGORIES.PUBLICATION]: consumePublicationResult,
} as {
  [key: string]: (data: any) => any
}
