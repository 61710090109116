import {
  CheckIcon,
  Combobox,
  type ComboboxItem,
  Group,
  Input,
  type MultiSelectProps,
  Pill,
  PillsInput,
  useCombobox,
} from '@mantine/core'

const DEFAULT_MAX_DISPLAYED_VALUES = 2

type ComboboxOption = string | ComboboxItem

type MultiSelectPropsType = MultiSelectProps & {
  data: ComboboxOption[]
  value: string[]
  onChange: (value: string[]) => void
  maxDisplayedValues?: number
}

export function MultiSelect({
  data,
  maxDisplayedValues = DEFAULT_MAX_DISPLAYED_VALUES,
  placeholder,
  value: selectedValues,
  onChange,
}: MultiSelectPropsType) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const handleValueSelect = (value: string) => {
    if (selectedValues.includes(value)) {
      onChange(selectedValues.filter((item) => item !== value))
    } else {
      onChange(value ? [value, ...selectedValues] : selectedValues)
    }
  }

  const values = selectedValues
    .slice(
      0,
      maxDisplayedValues === selectedValues.length
        ? maxDisplayedValues
        : maxDisplayedValues - 1,
    )
    .map((item) => {
      let label = item
      if (typeof data[0] === 'object') {
        const itemData = data.find((d) => (d as ComboboxItem).value === item)
        label = itemData ? (itemData as ComboboxItem).label : item
      }
      return (
        <Pill
          key={item}
          withRemoveButton
          onRemove={() => handleValueSelect(item)}
        >
          {label}
        </Pill>
      )
    })

  const formattedData = data.map((item) => {
    const value = typeof item === 'string' ? item : item.value
    const label = typeof item === 'string' ? item : item.label

    return { value, label }
  })

  const options = formattedData.map(({ value, label }) => (
    <Combobox.Option
      value={value}
      key={value}
      active={selectedValues.includes(value)}
    >
      <Group gap="sm">
        {selectedValues.includes(value) ? <CheckIcon size={12} /> : null}
        <span>{label}</span>
      </Group>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
      offset={0}
    >
      <Combobox.DropdownTarget>
        <PillsInput pointer onClick={() => combobox.toggleDropdown()}>
          <Pill.Group>
            {selectedValues.length > 0 ? (
              <>
                {values}
                {selectedValues.length > maxDisplayedValues && (
                  <Pill>
                    +{selectedValues.length - (maxDisplayedValues - 1)} more
                  </Pill>
                )}
              </>
            ) : (
              <Input.Placeholder>
                {placeholder ?? 'Pick one or more options'}
              </Input.Placeholder>
            )}
            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault()
                    handleValueSelect(selectedValues[0])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
