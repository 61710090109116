import { forwardRef } from 'react'
import { ActionIcon } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import {
  useHasDefaultFilterValues,
  useIsFilterExpanded,
  useMakeSearchFiltersFromCurrentPanelValues,
  useToggleFilterPanel,
} from '@/stores/filters-store'

type FilterToggleButtonProps = {
  activeColor?: null | string
  isFilterActive?: boolean
  fillIcon?: boolean
}
export const FilterToggleButton = forwardRef<
  HTMLButtonElement,
  FilterToggleButtonProps
>(({ isFilterActive = true, activeColor, fillIcon = true }, ref) => {
  const isFilterOpen = useIsFilterExpanded()
  const hasDefaultValues = useHasDefaultFilterValues()
  const toggleFilterButton = useToggleFilterPanel()
  const currentFilters = useMakeSearchFiltersFromCurrentPanelValues()

  return (
    <ActionIcon
      ref={ref}
      className="filterToggle"
      variant={isFilterOpen ? 'filled' : 'subtle'}
      size="lg"
      fw="normal"
      color={activeColor || 'black'}
      onClick={toggleFilterButton}
    >
      <Icon
        name={hasDefaultValues() ? 'filter_alt_off' : 'filter_alt'}
        filled={isFilterActive && fillIcon && currentFilters.length > 0}
      />
    </ActionIcon>
  )
})

FilterToggleButton.displayName = 'FilterToggleButton'
