import React from 'react'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'

interface SearchRef {
  handleSubmit: () => void
  focus: () => void
}

export function useSearchInterface() {
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  const [isSplit, setIsSplit] = useLocalStorage({
    key: 'split-search-active',
    defaultValue: isCommercial,
  })

  const searchRef = React.useRef<SearchRef>(null)

  const focus = () => {
    searchRef.current?.focus()
  }

  React.useEffect(() => {
    setIsSplit(isCommercial)
  }, [setIsSplit, isCommercial])

  const handleSubmit = () => {
    searchRef.current?.handleSubmit()
  }

  const handleSplitHotkey = (event: KeyboardEvent | React.KeyboardEvent) => {
    event.preventDefault()
    setIsSplit((state) => !state)
    setTimeout(() => {}, 0)
    focus()
  }

  const handleInlineHotkeyPress = (
    event: KeyboardEvent | React.KeyboardEvent,
  ) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === 'k') {
        handleSplitHotkey(event)
      } else if (event.key === 'Enter') {
        handleSubmit()
      }
    }
  }

  useHotkeys([
    ['mod+k', handleSplitHotkey],
    ['mod+Enter', handleSubmit],
  ])

  return {
    isSplit,
    searchRef,
    handleInlineHotkeyPress,
  }
}
