import { type FC, useCallback } from 'react'
import { Checkbox, Menu, Stack, Text } from '@mantine/core'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type { ImageryResult } from '@/utils/types/result-types'
import type { Dictionary } from 'lodash'

type PostfilterCheckboxButtonProps = {
  histogrammedOptions: Dictionary<ImageryResult[]>
  omittedOptions: string[]
  setNewOmittedOptions: (omittedOptions: string[]) => void
  label: string
  isMenu?: boolean
}

export const PostfilterCheckboxButton: FC<PostfilterCheckboxButtonProps> = ({
  histogrammedOptions,
  omittedOptions,
  setNewOmittedOptions,
  label,
  isMenu,
}) => {
  const makeCheckbox = useCallback(
    (optionKey: string) => {
      const isChecked = !omittedOptions.includes(optionKey)
      return (
        <Checkbox
          size="xs"
          key={`option_${optionKey}`}
          label={`${optionKey} (${histogrammedOptions[optionKey].length})`}
          checked={isChecked}
          onChange={() => {
            captureEvent(POSTHOG_EVENTS.EXPLORE.FILTER_BY, {
              filterName: label,
              value: optionKey,
              isOn: !isChecked, // Opposite of the value before change
            })
            setNewOmittedOptions(
              omittedOptions.includes(optionKey)
                ? omittedOptions
                : [...omittedOptions, optionKey],
            )
          }}
        />
      )
    },
    [histogrammedOptions, omittedOptions, setNewOmittedOptions, label],
  )

  return (
    <Stack gap="xs">
      <Text size="s">{label}</Text>
      <Stack gap="xs">
        {Object.keys(histogrammedOptions)
          .sort()
          .map((optionKey) =>
            isMenu ? (
              <Menu.Item key={optionKey} closeMenuOnClick={false}>
                {makeCheckbox(optionKey)}
              </Menu.Item>
            ) : (
              makeCheckbox(optionKey)
            ),
          )}
      </Stack>
    </Stack>
  )
}
