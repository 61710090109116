import { ActionIcon, Button, Group, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import pluralize from 'pluralize'

export interface HistorySelectedBarProps {
  count: number
  onClear: () => void
  onRemove: () => void
}

export function HistorySelectedBar({
  count,
  onClear,
  onRemove,
}: HistorySelectedBarProps) {
  return (
    <>
      <Group
        bg="#EEE"
        p="0.75rem"
        style={{ borderRadius: 'var(--mantine-radius-sm)' }}
      >
        <ActionIcon
          title="Go back"
          color="gray"
          variant="transparent"
          onClick={onClear}
        >
          <Icon name="clear" />
        </ActionIcon>
        <Text
          flex={1}
          style={{ fontWeight: 'bold' }}
        >{`${count} ${pluralize('item', count)} selected`}</Text>
        <Button size="xs" onClick={onRemove}>
          Remove
        </Button>
      </Group>
    </>
  )
}
