import { SEARCH_CATEGORIES } from '@/api/results'
import {
  DATA_CATEGORIES,
  DATA_SOURCES,
  RESULT_CATEGORIES,
} from '@/utils/constants'
import type {
  AnalyticResult,
  DataResult,
  ImageryResult,
  PropertyResult,
  PublicationResult,
  RawSearchResult,
  RelatedImageryResult,
  ResultWithId,
} from './raw-result-types'
import type {
  AttomResult,
  FileResult,
  FireResult,
  HazardPropertyResult,
  HazardRiskResult,
  InsightResult,
  NewsResult,
  PortResult,
  RegridResult,
  ShipResult,
  SocialMediaResult,
  VesselResult,
  VexcelImage,
  VexcelResult,
} from './result-types'

function resultsIncludesProperty(
  result: ResultWithId | [ResultWithId, ResultWithId],
  property: string,
  propertyValue: string,
): boolean {
  if (Array.isArray(result)) {
    return result.some((r) => r.properties[property] === propertyValue)
  }
  return result.properties[property] === propertyValue
}

export function isPropertyResult(
  result: ResultWithId,
): result is PropertyResult {
  return result.category === DATA_CATEGORIES.PROPERTY
}

export function isDataResult(result: RawSearchResult): result is DataResult {
  return result.category === DATA_CATEGORIES.DATA
}

export function isUp42(source: string) {
  return source === DATA_SOURCES.UP42
}

export function isUp42Result(result: ResultWithId) {
  return isUp42(result.source)
}

export const isSentinel = (source: string) => source === DATA_SOURCES.SENTINEL
export function isSentinelResult(result: ResultWithId) {
  return isSentinel(result.source)
}

export function isVexcel(result: ImageryResult) {
  return result.properties._dSource === DATA_SOURCES.VEXCEL
}

export function isBlackSky(source: string) {
  return source === DATA_SOURCES.BLACKSKY
}

export function isSatellogic(source: string) {
  return source === DATA_SOURCES.SATELLOGIC
}

export function isUmbra(source: string) {
  return source === DATA_SOURCES.UMBRA
}

export function isCapella(source: string) {
  return source === DATA_SOURCES.CAPELLA
}

export function isBlackSkyResult(result: ResultWithId) {
  return isBlackSky(result.source)
}

export function isSocialMediaResult(
  result: ResultWithId,
): result is SocialMediaResult {
  return result.category === DATA_CATEGORIES.SOCIAL_MEDIA
}

export function isFileResult(result: ResultWithId): result is FileResult {
  return result.category === RESULT_CATEGORIES.FILE
}

export function isFireResult(result: ResultWithId): result is FireResult {
  return resultsIncludesProperty(result, '_dProvider', 'FIRMS')
}

export function isNewsResult(result: ResultWithId): result is NewsResult {
  return result.category === RESULT_CATEGORIES.WEB_ARTICLE
}

export function isVesselResult(result: ResultWithId): result is VesselResult {
  return (
    resultsIncludesProperty(result, '_dProvider', 'SPIRE') &&
    resultsIncludesProperty(result, 'recordType', 'vessel')
  )
}

export function isPortResult(result: ResultWithId): result is PortResult {
  return (
    resultsIncludesProperty(result, '_dProvider', 'SPIRE') &&
    resultsIncludesProperty(result, 'recordType', 'portEvent')
  )
}
export function isShipResult(result: ResultWithId): result is ShipResult {
  return isVesselResult(result) || isPortResult(result)
}

export function isAttomResult(result: ResultWithId): result is AttomResult {
  return resultsIncludesProperty(result, '_dProvider', 'ATTOM')
}

export function isHazardPropertyResult(
  result: ResultWithId,
): result is HazardPropertyResult {
  return (
    result.properties?._dProvider === 'HAZARD' &&
    'property' in result.properties &&
    result.properties?.resultCategory === RESULT_CATEGORIES.HAZARD_PROPERTY
  )
}

export function isHazardRiskResult(
  result: ResultWithId,
): result is HazardRiskResult {
  return (
    result.properties._dProvider === 'HAZARD' &&
    !('property' in result.properties)
  )
}
export function isRegridResult(
  result: ResultWithId | [ResultWithId, ResultWithId],
): result is RegridResult | [RegridResult, VexcelResult] {
  return resultsIncludesProperty(result, '_dProvider', 'REGRID')
}

export const isRecognizedPropertyResult = (
  result: ResultWithId,
): result is PropertyResult =>
  isPropertyResult(result) ||
  isHazardPropertyResult(result) ||
  isRegridResult(result) ||
  isAttomResult(result)

export function isImageryResult(result: ResultWithId): result is ImageryResult {
  return [
    DATA_CATEGORIES.IMAGE,
    DATA_CATEGORIES.RELATED_IMAGERY,
    DATA_CATEGORIES.ANALYTIC,
  ].includes(result.category)
}
export function isAnalyticResult(
  result: ResultWithId,
): result is AnalyticResult {
  return [DATA_CATEGORIES.ANALYTIC].includes(result.category)
}

export function isRelatedImageryResult(
  result: ResultWithId,
): result is RelatedImageryResult {
  return result.category === DATA_CATEGORIES.RELATED_IMAGERY
}

export function isInsightResult(result: ResultWithId): result is InsightResult {
  return result.category === DATA_CATEGORIES.INSIGHT
}

export function isPublicationResult(
  result: ResultWithId,
): result is PublicationResult {
  return result.category === DATA_CATEGORIES.PUBLICATION
}

export function hasVexcelImages(result: ResultWithId): boolean {
  return (
    isRegridResult(result) &&
    Array.isArray(result.properties.vexcel_images) &&
    result.properties.vexcel_images.length > 0
  )
}

export function isVexcelResult(result: ResultWithId): result is VexcelResult {
  return result.properties.resultCategory === RESULT_CATEGORIES.VEXCEL
}

export function getResultCategory(result: ResultWithId): string {
  const resultCategory = result.properties.resultCategory || result.category
  const _dProvider = result.properties._dProvider

  if (resultCategory && resultCategory !== 'PROPERTY') {
    return resultCategory
  }

  switch (_dProvider) {
    case 'VEXCEL':
      return SEARCH_CATEGORIES.VEXCEL
    case 'REGRID': {
      const vexcelImages: VexcelImage[] =
        result.properties.vexcel_images?.vexcel_images ?? []

      if (vexcelImages.length > 0) {
        return RESULT_CATEGORIES.VEXCEL
      }

      return SEARCH_CATEGORIES.REGRID
    }
    case 'ATTOM':
      return SEARCH_CATEGORIES.ATTOM
    case 'CORELOGIC':
      return result.properties._dProvider === 'CORELOGIC' &&
        'hailRisk' in result.properties
        ? SEARCH_CATEGORIES.CORELOGIC_RISK
        : SEARCH_CATEGORIES.CORELOGIC_PROPERTY
    default:
      return result.properties._dProvider === 'HAZARD' &&
        !('property' in result.properties)
        ? SEARCH_CATEGORIES.HAZARD_RISK
        : SEARCH_CATEGORIES.HAZARD_PROPERTY
  }
}
