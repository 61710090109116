import { ModalsProvider } from '@mantine/modals'
import { FileUploadModal } from '@/components/lib/FileUpload'
import type React from 'react'
import { AddToCollectionModal } from './AddToCollectionModal'
import { AnalyzeCollectionModal } from './AnalyzeCollectionModal'
import { AnalyzeItemModal } from './AnalyzeItemModal'
import { CreateCollectionModal } from './CreateCollectionModal'
import { DeleteCollectionModal } from './DeleteCollectionModal'
import { DownloadItemModal } from './DownloadItemModal'
import { InviteUserModal } from './InviteUserModal'
import { RemoveAllHistoryModal } from './RemoveAllHistoryModal'
import { RemoveFromCollectionModal } from './RemoveFromCollectionModal'
import { ResultDetailsModal } from './ResultDetailsModal'
import { SaveSearchModal } from './SaveSearchModal'
import { SessionConfirmationModal } from './SessionConfirmationModal'
import { ShareCollectionModal } from './ShareCollectionModal'
import { ShareModal } from './ShareModal'
import { UpdateCollectionModal } from './UpdateCollectionModal'

export function DantiModalsProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <ModalsProvider
      modals={{
        addToCollection: AddToCollectionModal,
        removeFromCollection: RemoveFromCollectionModal,
        removeAllHistory: RemoveAllHistoryModal,
        createCollection: CreateCollectionModal,
        updateCollection: UpdateCollectionModal,
        shareCollection: ShareCollectionModal,
        deleteCollection: DeleteCollectionModal,
        analyzeCollection: AnalyzeCollectionModal,
        analyzeItem: AnalyzeItemModal,
        downloadItem: DownloadItemModal,
        uploadFile: FileUploadModal,
        share: ShareModal,
        resultDetails: ResultDetailsModal,
        saveSearch: SaveSearchModal,
        sessionConfirmation: SessionConfirmationModal,
        inviteUser: InviteUserModal,
      }}
    >
      {children}
    </ModalsProvider>
  )
}
