import * as React from 'react'
import { resolveLocation } from '@/api/google-maps'
import type {
  useAutoCompleteValues,
  useInputFields,
} from '@/features/Search/hooks'

type Inputs = ReturnType<typeof useInputFields>
type AutoComplete = ReturnType<typeof useAutoCompleteValues>

export const useLocationField = (
  inputs: Inputs,
  autoComplete: AutoComplete,
) => {
  const [isLoading, setIsLoading] = React.useState(false)
  React.useEffect(() => {
    if (inputs.locationValue) {
      setIsLoading(true)
    }
    // Use `locationValue` but only trigger when `debouncedLocationValue` changes
    void resolveLocation(inputs.locationValue, (options) => {
      setIsLoading(false)
      autoComplete.handleLocationUpdate(options)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoComplete.debouncedLocationValue])

  const handleLocationChange = (value: string) => {
    inputs.setLocationValue(value)
    autoComplete.handleLocationChange(value)
  }

  const handleOptionSubmit = (value: string) => {
    inputs.handleOptionSubmit(value)
    autoComplete.handleOptionSubmit()
  }

  const hasError = !!autoComplete.errorMessage
  return {
    ref: inputs.locationRef,
    value: inputs.locationValue,
    onChange: handleLocationChange,
    isLoading,
    data: autoComplete.data,
    error: hasError,
    onOptionSubmit: handleOptionSubmit,
  }
}
