import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makeCloudCoverFilter = (
  cloudCover: number,
): SearchApiFilterValue => {
  return {
    type: 'CLOUDCOVER',
    coverage: cloudCover,
  }
}
