/* eslint-disable no-case-declarations */
import { type MutableRefObject, useCallback, useEffect } from 'react'
import { notifications } from '@mantine/notifications'
import { emitter, EVENTS } from '@/events'
import { MAP_DRAW_MODES } from '@/features/DantiMap/constants.ts'
import { actOnMap, makeMapboxDraw } from '@/features/DantiMap/utils'
import { useSearch } from '@/features/Search/hooks'
import {
  UPLOADED_FEATURE_ID,
  useClearSearchHereMarker,
  useCurrentSearchGeometry,
  useDrawController,
  useDrawMode,
  useLastSearchPolygon,
  useSearchHereMarker,
  useSetCurrentSearchGeometry,
  useSetDrawController,
  useSetIsDrawing,
  useSetSearchHereMarker,
  useUploadedFeature,
} from '@/stores/map-draw-store'
import type { RadiusApiFilterValue } from '@/utils/types/filter-types.ts'
import type {
  DrawCreateEvent,
  DrawEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw'
import { centroid } from '@turf/turf'
import * as turf from '@turf/turf'
import type { Feature, FeatureCollection, Point, Polygon } from 'geojson'
import { type LngLatLike, type Map, Marker } from 'mapbox-gl'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic'

import classes from '@/components/lib/error-notification.module.css'

const LAST_SEARCH_POLY_SOURCE_NAME = 'lastSearchPolygon'
export const useSearchDrawing = (
  map: MutableRefObject<Map | null>,
  searchHereDiv: MutableRefObject<HTMLDivElement | null>,
) => {
  const searchHereMarker = useSearchHereMarker()
  const setSearchHereMarker = useSetSearchHereMarker()
  const clearSearchHereMarker = useClearSearchHereMarker()
  const lastSearchPolygon = useLastSearchPolygon()
  const uploadedFeature = useUploadedFeature()
  const drawController = useDrawController()
  const setDrawController = useSetDrawController()
  const setIsDrawing = useSetIsDrawing()
  const setCurrentSearchGeometry = useSetCurrentSearchGeometry()
  const currentSearchGeometry = useCurrentSearchGeometry()
  const drawMode = useDrawMode()

  const processDrawEvent = useCallback<(event: DrawEvent) => void>(
    (event: DrawEvent) => {
      switch (event.type) {
        case 'draw.create':
        case 'draw.update':
          const drawEvent = event as DrawUpdateEvent | DrawCreateEvent
          const polygon = drawEvent.features[0].geometry as Polygon
          const geojson = drawEvent.features[0]
          setIsDrawing(false)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (MapboxDrawGeodesic.isCircle(geojson)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            const center = MapboxDrawGeodesic.getCircleCenter(geojson)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            const radius = MapboxDrawGeodesic.getCircleRadius(geojson)
            setCurrentSearchGeometry({
              type: 'Point',
              coordinates: center as number[][],
              radius: radius as number,
            })
          } else {
            setCurrentSearchGeometry(polygon)
          }

          break
      }
    },
    [setCurrentSearchGeometry, setIsDrawing],
  )

  const { doLocationSearch, doRadiusSearch } = useSearch()

  // Clear search polygon on map unmount
  useEffect(() => {
    return () => {
      setCurrentSearchGeometry(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addSearchHereMarker = useCallback(() => {
    const polygon =
      (currentSearchGeometry as Polygon | Point) || uploadedFeature
    if (map.current && polygon) {
      const marker = new Marker(searchHereDiv.current!, { offset: [30, 30] })
      try {
        const polygonCentroid = centroid(polygon)
        if (polygonCentroid.geometry.coordinates) {
          actOnMap(() => {
            marker.setLngLat(polygonCentroid.geometry.coordinates as LngLatLike)
            marker.addTo(map.current as Map)
          })
          setSearchHereMarker(marker)
        }
      } catch (error) {
        console.error('Error calculating centroid:', error)
        notifications.clean()
        notifications.show({
          color: 'red',
          title: 'Invalid GEOJSON File',
          withBorder: true,
          message:
            'There was an error processing the file. Please make sure it is valid.',
          autoClose: 10000,
          withCloseButton: true,
          classNames: classes,
          styles: (theme) => ({
            root: {
              backgroundColor: '#D32F2F',
            },
            description: {
              color: theme.white,
            },
          }),
        })
      }
    }
  }, [
    currentSearchGeometry,
    map,
    searchHereDiv,
    setSearchHereMarker,
    uploadedFeature,
  ])

  useEffect(() => {
    if (map.current && (currentSearchGeometry || uploadedFeature)) {
      addSearchHereMarker()
    } else if (!currentSearchGeometry && searchHereMarker) {
      clearSearchHereMarker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchGeometry, uploadedFeature])

  useEffect(() => {
    if (uploadedFeature && drawController !== null) {
      actOnMap(() => {
        drawController?.set(turf.featureCollection([uploadedFeature]))
        setIsDrawing(false)
        drawController?.changeMode('direct_select', {
          featureId: UPLOADED_FEATURE_ID,
        })
      })
    }
  }, [map, drawController, setIsDrawing, uploadedFeature])

  useEffect(() => {
    const handleLoad = () => {
      actOnMap(() => {
        if (!map.current?.getSource('mapbox-gl-draw-cold')) {
          const drawController = makeMapboxDraw(map.current!)
          setDrawController(drawController)
          map.current?.addControl(drawController)
          map.current?.addSource(LAST_SEARCH_POLY_SOURCE_NAME, {
            type: 'geojson',
            data: turf.featureCollection([]),
          })
          map.current?.addLayer({
            id: `${LAST_SEARCH_POLY_SOURCE_NAME}_layer`,
            type: 'line',
            source: LAST_SEARCH_POLY_SOURCE_NAME,
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#202050',
              'line-width': 4,
              'line-opacity': 0.5,
              'line-dasharray': [5, 5],
            },
          })
        }
      })
    }

    emitter.on(EVENTS.map.load, handleLoad)
    emitter.on(EVENTS.map.drawCreated, processDrawEvent)
    emitter.on(EVENTS.map.drawUpdated, processDrawEvent)

    return () => {
      emitter.off(EVENTS.map.load, handleLoad)
      emitter.off(EVENTS.map.drawCreated, processDrawEvent)
      emitter.off(EVENTS.map.drawUpdated, processDrawEvent)
    }
  }, [map, processDrawEvent, setDrawController])

  useEffect(() => {
    const lastSearchPolygonSource = map.current?.getSource(
      LAST_SEARCH_POLY_SOURCE_NAME,
    ) as mapboxgl.GeoJSONSource

    const feature = lastSearchPolygon
      ? turf.feature(lastSearchPolygon)
      : turf.featureCollection([])
    actOnMap(() => {
      lastSearchPolygonSource?.setData(feature as Feature | FeatureCollection)
    })
  }, [lastSearchPolygon, map])

  const onClickCallback = useCallback(() => {
    const { coordinates } = currentSearchGeometry as Polygon | Point
    const isPointSearch = currentSearchGeometry?.type === 'Point'
    const locationType = isPointSearch ? 'latLong' : 'polygon'

    const radiusFilter = {
      type: 'RADIUS',
      geometry: {
        type: 'Point',
        coordinates: currentSearchGeometry?.coordinates,
      },
      radius: currentSearchGeometry?.radius as number,
      radiusUnits: 'kilometers',
    }
    const positions = currentSearchGeometry
      ? isPointSearch
        ? [coordinates[1], coordinates[0]]
        : currentSearchGeometry.coordinates[0]
      : (uploadedFeature?.geometry as Polygon).coordinates[0]

    if (positions) {
      actOnMap(() => {
        searchHereDiv.current?.remove()
        if (drawMode === MAP_DRAW_MODES.CIRCLE) {
          doRadiusSearch(radiusFilter as RadiusApiFilterValue)
        } else {
          doLocationSearch(JSON.stringify(positions), locationType)
        }
        clearSearchHereMarker()
        setCurrentSearchGeometry(null)
      })
    }
  }, [
    clearSearchHereMarker,
    currentSearchGeometry,
    doLocationSearch,
    doRadiusSearch,
    drawMode,
    searchHereDiv,
    setCurrentSearchGeometry,
    uploadedFeature?.geometry,
  ])

  return { onClickCallback }
}
