import { DataDisplayCard } from './DataDisplayCard'

import styles from './data-display.module.css'

type SOVDataDisplayProps = {
  fields: { title?: React.ReactNode; value?: React.ReactNode }[]
}

export const SOVDataDisplay = ({ fields }: SOVDataDisplayProps) => {
  return (
    <div className={styles['sov-data-display']}>
      {fields.map((field) => (
        <DataDisplayCard key={crypto.randomUUID()} {...field} />
      ))}
    </div>
  )
}
