import {
  useDrawController,
  useSearchHereMarker,
  useSetLastSearchPolygon,
  useSetSearchHereMarker,
} from './hooks'

export const useClearSearchHereMarker = () => {
  const searchHereMarker = useSearchHereMarker()
  const setSearchHereMarker = useSetSearchHereMarker()
  return () => {
    if (searchHereMarker) {
      searchHereMarker.remove()
      setSearchHereMarker(null)
    }
  }
}

export const useClearSearchPolygon = () => {
  const drawController = useDrawController()
  const setLastSearchPolygon = useSetLastSearchPolygon()
  const clearSearchHereMarker = useClearSearchHereMarker()
  return () => {
    drawController?.deleteAll()
    setLastSearchPolygon(null)
    clearSearchHereMarker()
  }
}
