import React from 'react'
import { Stack, Text } from '@mantine/core'
import { Slider } from '@/components/lib/Slider.tsx'
import { useAngleFilter, useSetFilters } from '@/stores/filters-store'

const nadirAngles = Array.from({ length: 6 }).map((_, index) => ({
  label: index < 10 ? `${index * 10}°` : 'Any',
  value: index * 10,
}))

export function CameraAngle() {
  const nadirAngle = useAngleFilter()
  const setFilters = useSetFilters()
  const [angleOffNadir, setAngleOffNadir] = React.useState(
    Number(nadirAngle) || 50,
  )

  React.useEffect(() => {
    setAngleOffNadir(Number(nadirAngle) || 50)
  }, [nadirAngle])

  return (
    <Stack gap="xs">
      <Text size="xs" c="#3BADED">
        {angleOffNadir < 50
          ? `Maximum ${angleOffNadir}° off Nadir`
          : 'Any angle off Nadir'}
      </Text>
      <Slider
        mt="0.375rem"
        min={0}
        max={50}
        step={1}
        marks={nadirAngles}
        value={angleOffNadir}
        label={(angle) => (angle < 50 ? `${angle}°` : 'Any')}
        aria-label="Angle Off Nadir"
        onChange={(value) => setAngleOffNadir(value)}
        onChangeEnd={(value) => setFilters({ angle: value.toString() })}
      />
    </Stack>
  )
}
