import React from 'react'
import { Stack, Text } from '@mantine/core'
import houseImage from '@/assets/overhead-house.png'
import { Slider } from '@/components/lib/Slider.tsx'
import { useQualityFilter, useSetFilters } from '@/stores/filters-store'

const qualities = [
  { label: '5 cm', value: '0.05', blurRadius: 0 },
  { label: '10 cm', value: '0.1', blurRadius: 1 },
  { label: '15 cm', value: '.15', blurRadius: 2 },
  { label: '30 cm', value: '0.3', blurRadius: 3 },
  { label: '50 cm', value: '0.5', blurRadius: 4 },
  { label: '1 m', value: '1', blurRadius: 5 },
  { label: '3 m', value: '3', blurRadius: 6 },
  { label: '10 m', value: '10', blurRadius: 7 },
  { label: '10 m+', value: '5000', blurRadius: 8 },
]

function getQualityIndex(value: string) {
  const qualityIndex = qualities.findIndex((quality) => quality.value === value)

  return qualityIndex > -1 ? qualityIndex : qualities.length - 1
}

export function Quality() {
  const quality = useQualityFilter()
  const setFilters = useSetFilters()
  const [qualityIndex, setQualityIndex] = React.useState(
    getQualityIndex(quality),
  )

  React.useEffect(() => {
    setQualityIndex(getQualityIndex(quality))
  }, [quality])

  return (
    <Stack gap="xs">
      <Text size="xs" c="#3BADED">
        {`${qualities[qualityIndex].label} or Better`}
      </Text>
      <div
        style={{
          width: '100%',
          height: '8rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {[0, 1].map((value) => (
          <img
            key={value}
            alt="blur"
            src={houseImage}
            style={{
              objectFit: 'cover',
              position: 'absolute',
              left: '-5%',
              top: '-5%',
              width: '110%',
              height: '110%',
              filter: `blur(${qualities[qualityIndex].blurRadius}px)`,
            }}
          />
        ))}
      </div>
      <Slider
        mt="sm"
        label={(value) => qualities[value].label}
        value={qualityIndex}
        onChange={(value) => setQualityIndex(value)}
        onChangeEnd={(value) => setFilters({ quality: qualities[value].value })}
        marks={qualities.map(({ label }, index) => ({ value: index, label }))}
        min={0}
        max={qualities.length - 1}
      />
    </Stack>
  )
}
