import {
  type DateValue,
  type SearchApiFilterValue,
  TimePreset,
} from '@/utils/types/filter-types.ts'

/**
 * Convert date to ideal stac search format.
 * @param {Date} date - The date to convert to a custom time string.
 * @returns {string} Returns a custom time string.
 */
const isoToCustomTime = (date: Date): string => {
  return date.toISOString().replaceAll(/\.\d+Z$/g, 'Z')
}

const DAY = 24 * 60 * 60 * 1000
const now = Date.now()

export const makeTimeFilter = (params: {
  timePreset: TimePreset
  customTimeStart: DateValue
  customTimeEnd?: DateValue
}): SearchApiFilterValue | undefined => {
  function makeFilter(
    start: Date,
    end: DateValue = new Date(),
  ): SearchApiFilterValue {
    return {
      type: 'TIMEFRAME',
      start: isoToCustomTime(start),
      end: isoToCustomTime(end ?? new Date()),
    }
  }
  switch (params.timePreset) {
    case TimePreset.Last24Hours:
      return makeFilter(new Date(now - DAY))
    case TimePreset.Last7Days:
      return makeFilter(new Date(now - 7 * DAY))
    case TimePreset.Last30Days:
      return makeFilter(new Date(now - 30 * DAY))
    case TimePreset.Last90Days:
      return makeFilter(new Date(now - 90 * DAY))
    case TimePreset.Custom:
      if (params.customTimeStart && params.customTimeEnd) {
        return makeFilter(
          new Date(params.customTimeStart),
          new Date(params.customTimeEnd),
        )
      }
  }
}
