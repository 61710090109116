/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  '\n  query OrgUsers(\n    $search: String\n    $first: Int\n    $after: String\n    $before: String\n  ) {\n    me {\n      id\n      org {\n        id\n        users(search: $search, first: $first, after: $after, before: $before) {\n          pageInfo {\n            startCursor\n            endCursor\n            hasNextPage\n            hasPreviousPage\n          }\n          nodes {\n            id\n            firstName\n            lastName\n            email\n            role\n          }\n        }\n      }\n    }\n  }\n':
    types.OrgUsersDocument,
  '\n  query SourcesByUser {\n    me {\n      id\n      sources {\n        nodes {\n          provider\n        }\n      }\n    }\n  }\n':
    types.SourcesByUserDocument,
  '\n  mutation SubmitFeedback($newFeedback: NewFeedback!) {\n    submitFeedback(newFeedback: $newFeedback) {\n      id\n      queryId\n      feedback\n      customFeedback\n    }\n  }\n':
    types.SubmitFeedbackDocument,
  '\n  query HasActiveSubscription {\n    me {\n      id\n      org {\n        id\n        owner {\n          id\n          hasActiveSubscription\n        }\n      }\n    }\n  }\n':
    types.HasActiveSubscriptionDocument,
  '\n  query UserRole {\n    me {\n      id\n      role\n    }\n  }\n':
    types.UserRoleDocument,
  '\n  query UserID {\n    me {\n      id\n    }\n  }\n': types.UserIdDocument,
  '\n  mutation ResetPassword($userId: ID!) {\n    resetPassword(userId: $userId)\n  }\n':
    types.ResetPasswordDocument,
  '\n  mutation RemoveUserFromOrg($userId: ID!) {\n    removeUserFromOrg(userId: $userId)\n  }\n':
    types.RemoveUserFromOrgDocument,
  '\n  query queries_ProfileQuery {\n    me {\n      id\n      id\n      firstName\n      lastName\n      address\n      address2\n      role\n      city\n      state\n      zip\n      country\n      email\n    }\n  }\n':
    types.Queries_ProfileQueryDocument,
  '\n  mutation UpdateProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      firstName\n      lastName\n      address\n      address2\n      city\n      state\n      zip\n      country\n      email\n    }\n  }\n':
    types.UpdateProfileDocument,
  '\n  mutation UpdateUserRole($userId: ID!, $role: OrgRole!) {\n    updateUserRole(userId: $userId, role: $role) {\n      id\n    }\n  }\n':
    types.UpdateUserRoleDocument,
  '\n  mutation InviteUsers($emails: [String!]!, $role: OrgRole!) {\n    inviteUsers(emails: $emails, role: $role)\n  }\n':
    types.InviteUsersDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OrgUsers(\n    $search: String\n    $first: Int\n    $after: String\n    $before: String\n  ) {\n    me {\n      id\n      org {\n        id\n        users(search: $search, first: $first, after: $after, before: $before) {\n          pageInfo {\n            startCursor\n            endCursor\n            hasNextPage\n            hasPreviousPage\n          }\n          nodes {\n            id\n            firstName\n            lastName\n            email\n            role\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query OrgUsers(\n    $search: String\n    $first: Int\n    $after: String\n    $before: String\n  ) {\n    me {\n      id\n      org {\n        id\n        users(search: $search, first: $first, after: $after, before: $before) {\n          pageInfo {\n            startCursor\n            endCursor\n            hasNextPage\n            hasPreviousPage\n          }\n          nodes {\n            id\n            firstName\n            lastName\n            email\n            role\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SourcesByUser {\n    me {\n      id\n      sources {\n        nodes {\n          provider\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SourcesByUser {\n    me {\n      id\n      sources {\n        nodes {\n          provider\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SubmitFeedback($newFeedback: NewFeedback!) {\n    submitFeedback(newFeedback: $newFeedback) {\n      id\n      queryId\n      feedback\n      customFeedback\n    }\n  }\n',
): (typeof documents)['\n  mutation SubmitFeedback($newFeedback: NewFeedback!) {\n    submitFeedback(newFeedback: $newFeedback) {\n      id\n      queryId\n      feedback\n      customFeedback\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HasActiveSubscription {\n    me {\n      id\n      org {\n        id\n        owner {\n          id\n          hasActiveSubscription\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query HasActiveSubscription {\n    me {\n      id\n      org {\n        id\n        owner {\n          id\n          hasActiveSubscription\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserRole {\n    me {\n      id\n      role\n    }\n  }\n',
): (typeof documents)['\n  query UserRole {\n    me {\n      id\n      role\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserID {\n    me {\n      id\n    }\n  }\n',
): (typeof documents)['\n  query UserID {\n    me {\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ResetPassword($userId: ID!) {\n    resetPassword(userId: $userId)\n  }\n',
): (typeof documents)['\n  mutation ResetPassword($userId: ID!) {\n    resetPassword(userId: $userId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveUserFromOrg($userId: ID!) {\n    removeUserFromOrg(userId: $userId)\n  }\n',
): (typeof documents)['\n  mutation RemoveUserFromOrg($userId: ID!) {\n    removeUserFromOrg(userId: $userId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query queries_ProfileQuery {\n    me {\n      id\n      id\n      firstName\n      lastName\n      address\n      address2\n      role\n      city\n      state\n      zip\n      country\n      email\n    }\n  }\n',
): (typeof documents)['\n  query queries_ProfileQuery {\n    me {\n      id\n      id\n      firstName\n      lastName\n      address\n      address2\n      role\n      city\n      state\n      zip\n      country\n      email\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      firstName\n      lastName\n      address\n      address2\n      city\n      state\n      zip\n      country\n      email\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      firstName\n      lastName\n      address\n      address2\n      city\n      state\n      zip\n      country\n      email\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateUserRole($userId: ID!, $role: OrgRole!) {\n    updateUserRole(userId: $userId, role: $role) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateUserRole($userId: ID!, $role: OrgRole!) {\n    updateUserRole(userId: $userId, role: $role) {\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation InviteUsers($emails: [String!]!, $role: OrgRole!) {\n    inviteUsers(emails: $emails, role: $role)\n  }\n',
): (typeof documents)['\n  mutation InviteUsers($emails: [String!]!, $role: OrgRole!) {\n    inviteUsers(emails: $emails, role: $role)\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
