import * as React from 'react'
import { useKeyedState } from '@/hooks/use-keyed-state'
import { useStoredSplitSearch } from '@/stores/queries-store'

export const stateKeys = {
  location: 'location',
  subject: 'subject',
}

export const useInputFields = () => {
  const storedSplitSearch = useStoredSplitSearch()

  const [locationValue, setLocationValue] = useKeyedState(
    stateKeys.location,
    storedSplitSearch?.location ?? '',
  )
  const [subjectValue, setSubjectValue] = useKeyedState(
    stateKeys.subject,
    storedSplitSearch?.subject ?? '',
  )

  const locationRef = React.useRef<HTMLInputElement>(null)
  const subjectRef = React.useRef<HTMLInputElement>(null)

  const blur = () => {
    locationRef.current?.blur()
    subjectRef.current?.blur()
  }

  const focus = () => {
    locationRef.current?.focus()
  }

  const handleOptionSubmit = (value: string) => {
    setLocationValue(value)
    subjectRef.current?.focus()
  }

  return {
    blur,
    focus,
    locationRef,
    subjectRef,
    locationValue,
    setLocationValue,
    subjectValue,
    setSubjectValue,
    handleOptionSubmit,
    subjectProps: {
      ref: subjectRef,
      value: subjectValue,
      onChange: setSubjectValue,
    },
  }
}
