import { Stack } from '@mantine/core'
import { ParentSize } from '@visx/responsive'
import type React from 'react'
import { ResultsTimeline } from './ResultsTimeline'

type ResultsTimelineContainerProps = {
  isSmall: boolean
}
export const ResultsTimelineContainer: React.FC<
  ResultsTimelineContainerProps
> = ({ isSmall }) => {
  return (
    <Stack
      gap="xs"
      mt="xs"
      pl="sm"
      pr="sm"
      style={{
        borderRadius: '4px',
        border: '1px solid #e5e5e5',
      }}
    >
      <ParentSize style={{ width: '100%' }} debounceTime={50}>
        {({ width: visWidth }) => {
          return <ResultsTimeline width={visWidth} isSmall={isSmall} />
        }}
      </ParentSize>
    </Stack>
  )
}
