import { createTheme } from '@mantine/core'
import { COLORS } from './colors'

export const theme = createTheme({
  colors: COLORS,
  black: '#424242',
  fontFamily: 'Roboto, sans-serif',
  fontFamilyMonospace: 'Roboto Mono, monospace',
  radius: {
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.875rem',
  },
  spacing: {
    xs: '0.5rem',
    sm: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '3rem',
  },
  headings: {
    fontFamily: 'Roboto, sans-serif',
    sizes: {
      h1: { fontSize: '2.25rem', lineHeight: '1.25', fontWeight: '700' },
      h2: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '700' },
      h3: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '700' },
      h4: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '400' },
      h5: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '400' },
      h6: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '400' },
    },
  },
})
