import { useQueryClient } from '@tanstack/react-query'
import { useAddToCollection } from './use-add-to-collection'

export const useRefreshCollections = () => {
  const queryClient = useQueryClient()
  const { refresh } = useAddToCollection()

  return () => {
    void queryClient.invalidateQueries({
      queryKey: ['collections'],
    })
    refresh()
  }
}
