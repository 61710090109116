import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Text } from '@mantine/core'
import { usePaginatedResults } from '@/api/results'
import { renderers } from '@/features/SingleObjectView/views'
import type { ResultWithId } from '@/utils/types/result-types'

export function SingleObjectView() {
  const { resultsById } = usePaginatedResults()
  const { itemId } = useParams()
  const [item, setItem] = React.useState<ResultWithId>()
  const [itemNotAvailable, setItemNotAvailable] = React.useState(false)

  React.useEffect(() => {
    if (itemId) {
      const item = itemId ? resultsById[itemId] : undefined
      if (item) {
        setItem(item)
      } else {
        setItemNotAvailable(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])

  React.useEffect(() => {
    if (itemId) {
      const item = resultsById[itemId]
      if (item) {
        setItemNotAvailable(false)
        setItem(item)
      }
    }
  }, [resultsById, itemId])

  if (itemNotAvailable) {
    return <Text>Item not available</Text>
  }
  if (!item) {
    return <Text>Loading...</Text>
  }
  const resultCategory = item.properties.resultCategory as string

  const Renderer = renderers[resultCategory as keyof typeof renderers]
  return <Renderer item={item} />
}
