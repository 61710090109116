import {
  useImageProvenanceDataMetadata,
  usePlatformDataMetadata,
} from '@/api/data-metadata/use-data-metadata.ts'
import { MultiSelect } from '@/components/MultiSelect'
import { usePlatformsFilter, useSetPlatforms } from '@/stores/filters-store'

export function Platforms() {
  const platformsFilter = usePlatformsFilter()
  const setPlatformsFilter = useSetPlatforms()
  const platformMap = usePlatformDataMetadata()
  const { platforms = [] } = useImageProvenanceDataMetadata()

  const platformOptions = platforms.map((platform) => ({
    value: platform,
    label: platformMap[platform] ?? platform,
  }))

  return (
    <MultiSelect
      value={platformsFilter ?? []}
      onChange={setPlatformsFilter}
      placeholder="Any"
      data={platformOptions}
    />
  )
}
