import { isVesselResult } from '@/utils/types/result-type-checkers.ts'
import type { ShipResult } from '@/utils/types/result-types.ts'

export const getShipName = (result: ShipResult) =>
  (isVesselResult(result)
    ? result.properties.staticData.name
    : result.properties.vessel.staticData.name) ?? 'Unknown Vessel'

export const getShipLocation = (result: ShipResult) =>
  isVesselResult(result)
    ? result.properties.lastPositionUpdate
    : result.properties.location.centerPoint

export const getShipDetails = (result: ShipResult) => {
  if (isVesselResult(result)) {
    const { name, imo, mmsi, shipType, flag, callsign } =
      result.properties.staticData
    const {
      latitude,
      longitude,
      navigationalStatus,
      updateTimestamp: lastUpdateTimestamp,
    } = result.properties.lastPositionUpdate || {}
    const { destination, draught, eta } = result.properties.currentVoyage || {}
    return {
      type: 'vessel',
      name: name || callsign || 'Unknown Vessel',
      imo,
      mmsi,
      lastUpdateTimestamp,
      latitude,
      longitude,
      navigationalStatus: navigationalStatus
        ? navigationalStatus.toLowerCase()
        : null,
      destination,
      draught,
      eta,
      shipType: shipType ? shipType.toLowerCase() : null,
      flag,
      callSign: callsign,
    }
  }
  const { name, imo, mmsi, shipType, callsign } =
    result.properties.vessel.staticData
  return {
    type: 'port',
    name: name || callsign || 'Unknown Vessel',
    imo,
    mmsi,
    lastUpdateTimestamp: result.properties.updateTimestamp,
    latitude: result.properties.location.centerPoint.latitude,
    longitude: result.properties.location.centerPoint.longitude,
    shipType: shipType ? shipType.toLowerCase() : null,
    flag: result.properties.location.country,
    callSign: callsign,
  }
}
