import type { TimelineDoctypes } from '@/features/ResultsTimeline/types'

export const BAR_FILL_COLOR: {
  [key in TimelineDoctypes]: { normal: string; selected: string }
} = {
  IMAGE: { normal: '#42A5F5', selected: '#2b5ba9' },
  ORTHO: { normal: '#42A5F5', selected: '#2b5ba9' },
  PUBLICATION: { normal: '#FFA726', selected: '#FFA726' },
  SOCIAL_MEDIA: {
    normal: 'rgba(94, 53, 177, 1)',
    selected: 'rgba(94, 53, 177, 1)',
  },
  FIRE: { normal: 'rgba(211, 47, 47, 1)', selected: 'rgba(211, 47, 47, 1)' },
  SHIP: { normal: '#00A5B0', selected: '#00A5B0' },
  PROPERTY: { normal: '#C2185B', selected: '#C2185B' },
}

export const FOCUS_HIST_HEIGHT = 60
export const INNER_MARGIN = 10
export const CONTEXT_HIST_HEIGHT = 25
export const SVG_TOP_PADDING = 20
export const TIMELINE_INSET = 32

export const BUCKET_MAX_THRESHOLD = 100

export const timeBucketUnitKeys = [
  'HOUR',
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
] as const

export const TimeBucketUnit: {
  [key in (typeof timeBucketUnitKeys)[number]]: string
} = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
} as const
