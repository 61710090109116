import { Button, Group, Menu, Paper, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { openSaveSearchModal } from '@/features/AppLayout/Modals'
import {
  FilterPanel,
  FilterToggleButton,
  useProviders,
} from '@/features/Filters'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useCurrentQuery } from '@/stores/queries-store'
import { FEATURE_FLAG_VALUES, SAVED_SEARCH_MODES } from '@/utils/constants'
import { classes } from '@/utils/ui-helpers'

import styles from './queryHeader.module.css'

export function QueryHeader(props: { fixed?: boolean }) {
  const currentQuery = useCurrentQuery()
  const { newProviders } = useProviders()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  return (
    <Paper
      className={classes(styles.queryContainer, props.fixed && styles.fixed)}
      data-testid="query-header"
    >
      <style>{`body { --danti-head-height: 120px; }`}</style>
      <header className={styles.queryHeader}>
        <Group justify="space-between" gap="sm" w="100%" wrap="nowrap">
          <Text
            title={currentQuery}
            fw="bold"
            data-cy="query-header-query"
            lineClamp={1}
          >
            Q: {currentQuery}
          </Text>
          <Group wrap="nowrap">
            {!isCommercial && (
              <FilterToggleButton data-cy="query-header-filter-toggle" />
            )}
            <Menu trigger="hover" shadow="md" position="bottom-start">
              <Menu.Target>
                <Button
                  size="xs"
                  rightSection={<Icon name="arrow_drop_down" />}
                >
                  SAVE SEARCH
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  key="save"
                  leftSection={<Icon name="save" />}
                  onClick={() =>
                    openSaveSearchModal({
                      isNew: true,
                      providers: newProviders,
                      params: {
                        query: currentQuery,
                        name: currentQuery,
                        emailNotification: '',
                        mode: SAVED_SEARCH_MODES.search,
                        locations: [],
                      },
                    })
                  }
                  p="xs"
                  className={styles['menu-hover']}
                >
                  Save this search
                </Menu.Item>
                <Menu.Item
                  key="feed"
                  leftSection={<Icon name="history" />}
                  onClick={() =>
                    openSaveSearchModal({
                      isNew: true,
                      providers: newProviders,
                      params: {
                        query: currentQuery,
                        name: currentQuery,
                        emailNotification: '',
                        mode: SAVED_SEARCH_MODES.feed,
                        locations: [],
                      },
                    })
                  }
                  p="xs"
                  className={styles['menu-hover']}
                >
                  Create a new AOI saved search
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </header>
      <FilterPanel data-cy="query-header-filter-panel" />
    </Paper>
  )
}
