import { Button, Menu } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import { SORT_FIELDS, type SORT_TYPES } from '@/features/Collections/constants'
import { useCollectionsState } from '@/stores/collections'

export interface TableHeaderSortCellProps {
  label: string
  sortType: ValueOf<typeof SORT_TYPES>
  setSort?: (sort: CollectionSortType) => void
}

export function TableHeaderSortCell({
  label,
  setSort,
  sortType,
}: TableHeaderSortCellProps) {
  const collectionsSetSort = useCollectionsState().setSort
  const handleClick = (value?: CollectionSortType) => {
    if (value) {
      ;(setSort || collectionsSetSort)(value)
    }
  }

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button size="xs" radius="xl" bg="#FAFAFA" style={{ color: 'black' }}>
          {label}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {SORT_FIELDS[sortType].map(({ value, label }) => (
          <Menu.Item key={label} onClick={() => handleClick(value)}>
            {label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
