import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { MantineProvider } from '@mantine/core'
import { router } from '@/features/AppRouting'
import { graphqlClient } from '@/graphql-client'
import { configureSentry } from '@/hooks/use-sentry-metadata-syncing'
import { DantiAuthProvider } from '@/utils/authentication'
import { DantiPostHogProvider } from '@/utils/DantiPostHogProvider'
import { QueryProvider } from '@/utils/query-provider'
import { theme } from '@/utils/theme/theme'
import { ApolloProvider } from '@apollo/client'
import { SingletonHooksContainer } from 'react-singleton-hook'
import { kcContext as kcLoginThemeContext } from './keycloak-theme/login/KcContext'

import '@mantine/core/styles.layer.css'
import '@mantine/notifications/styles.layer.css'
import '@mantine/dates/styles.layer.css'
import './main.css'

configureSentry()

// eslint-disable-next-line react-refresh/only-export-components
const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'))

const rootElement = document.querySelector('#root') as Element

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <React.StrictMode>
      <Suspense>
        {kcLoginThemeContext === undefined ? (
          <DantiPostHogProvider>
            <DantiAuthProvider>
              <QueryProvider>
                <ApolloProvider client={graphqlClient}>
                  <SingletonHooksContainer />
                  <MantineProvider theme={theme}>
                    <RouterProvider router={router} />
                  </MantineProvider>
                </ApolloProvider>
              </QueryProvider>
            </DantiAuthProvider>
          </DantiPostHogProvider>
        ) : (
          <MantineProvider theme={theme}>
            <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
          </MantineProvider>
        )}
      </Suspense>
    </React.StrictMode>,
  )
}
