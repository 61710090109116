import type { useUsers } from '@/api/user'
import { OrgRole } from '@/gql/graphql'

export const RankedOrgRoles: OrgRole[] = [
  OrgRole.Viewer,
  OrgRole.Editor,
  OrgRole.Admin,
  OrgRole.Owner,
]

export type User = NonNullable<ReturnType<typeof useUsers>['users'][0]>

export interface InviteUsersForm {
  emails: string[]
  role: OrgRole
}
