import { Card, Group, Pill, SimpleGrid, Stack, Text } from '@mantine/core'
import { useCollections } from '@/api/collections'
import type { Collection } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon.tsx'
import { useNavigateToCollection } from '@/features/AppRouting/hooks'
import { ICONS } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { CollectionMenu } from './CollectionMenu'

const collectionDatetime = (collection: Collection) =>
  formatDatetime((collection.lastActivity || collection.createdOn).toString())

export function CollectionGrid() {
  const { collections } = useCollections()
  const navigateToCollection = useNavigateToCollection()
  const onCollectionClick = (collection: Collection) => () =>
    navigateToCollection(collection.id)
  const folderIconStyle = { style: { color: '#1F5CB9' }, size: 32 }
  const icons = {
    folder: <Icon name={ICONS.folder} {...folderIconStyle} />,
    specialfolder: <Icon name={ICONS.folderSpecial} {...folderIconStyle} />,
    update: <Icon name={ICONS.update} color="gray" size="xs" />,
  }

  return (
    <SimpleGrid cols={3} spacing="md" flex={1} mt="md">
      {collections.map((collection) => (
        <Card
          key={collection.id}
          p="sm"
          withBorder
          radius="md"
          bg="#FAFAFA"
          style={{ cursor: 'pointer' }}
        >
          <Stack justify="space-between" mah={200}>
            <Stack gap="xs" onClick={onCollectionClick(collection)}>
              <Group wrap="nowrap" gap="sm" align="start">
                {collection.refId ? icons.specialfolder : icons.folder}

                <Stack gap="xs" align="start">
                  <Text c="#424242" lineClamp={1} fz={18} fw="bold">
                    {collection.name}
                  </Text>

                  <Group gap={4}>
                    {icons.update}
                    <Text size="xs" c="dimmed">
                      {collectionDatetime(collection)}
                    </Text>
                  </Group>
                </Stack>

                <div style={{ marginLeft: 'auto' }}>
                  <CollectionMenu
                    collection={collection}
                    orientation="vertical"
                  />
                </div>
              </Group>

              <Group>
                {collection.status === 'NEW' && (
                  <Pill key="NEW" size="xs" bg="#F1C21B" fw="bold">
                    NEW
                  </Pill>
                )}
              </Group>
            </Stack>
          </Stack>
        </Card>
      ))}
    </SimpleGrid>
  )
}
