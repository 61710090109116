import React from 'react'
import { useQueryId } from '@/api/results'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import { emitter, EVENTS } from '@/events'
import { useOrthoImagerySources } from '@/features/DantiMap/hooks'
import {
  FireReferenceCard,
  NewsReferenceCard,
  ShipReferenceCard,
  SocialMediaReferenceCard,
} from '@/features/ResultCards'
import {
  useActiveOrthoLayerGroupIds,
  useToggleOrthoLayerGroupId,
  useZoomToOrthoLayer,
} from '@/stores/map-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types'
import type {
  FireResult,
  PublicationResult,
  ResultWithId,
  ShipResult,
  SocialMediaResult,
} from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'
import * as turf from '@turf/turf'
import type { TABS_MAP } from './constants'
import { OrthoThumbnail } from './OrthoThumbnail'
import { Tab } from './Tab'

import style from '@/features/ResultCards/ReferenceCards/components/reference-card.module.css'

const TAB_ORDER = [
  SEARCH_CATEGORIES.IMAGE,
  SEARCH_CATEGORIES.PUBLICATION,
  SEARCH_CATEGORIES.SOCIAL_MEDIA,
  SEARCH_CATEGORIES.FIRE,
  SEARCH_CATEGORIES.SHIP,
] as const

type TabConfig = {
  value: string
  category: string
  content: JSX.Element[]
  tab: JSX.Element
  results: ResultWithId[] | OrthoImageryLayerGroup[]
}

export function useResultTabs() {
  const [activeTab, setActiveTab] = React.useState('images')
  const [activeCategory, setActiveCategory] = React.useState<string>(
    SEARCH_CATEGORIES.IMAGE,
  )

  const currentQueryId = useQueryId()
  const { categorized: timelineCategorized, ids } = useTimelineFilteredResults()
  const { orthoLayerGroups } = useOrthoImagerySources()
  const imageResults = orthoLayerGroups
  const toggleOrthoLayerId = useToggleOrthoLayerGroupId()
  const zoomToOrthoLayer = useZoomToOrthoLayer()
  const activeOrthoLayerIds = useActiveOrthoLayerGroupIds()

  const hasResults = ids.length > 0

  const renderCard = (result: ResultWithId, contents: React.ReactNode) => {
    const onClick = () => {
      const center = turf.center(result.geometry).geometry.coordinates
      emitter.emit(EVENTS.map.flyTo, {
        center: [center[0], center[1]],
        zoom: 10,
      })
    }
    return (
      <div
        style={{ display: 'inline-block' }}
        onClick={onClick}
        key={result.id}
      >
        {contents}
      </div>
    )
  }

  const renderTab = (
    value: keyof typeof TABS_MAP,
    category: keyof typeof timelineCategorized,
  ) => (
    <Tab
      key={crypto.randomUUID()}
      value={value}
      total={timelineCategorized[category].length}
    />
  )

  const tabs = {
    [SEARCH_CATEGORIES.IMAGE]: {
      category: SEARCH_CATEGORIES.IMAGE,
      value: 'images' as const,
      tab: (
        <Tab
          key={crypto.randomUUID()}
          value="images"
          total={imageResults.length}
        />
      ),
      content: imageResults.map((result: OrthoImageryLayerGroup) => {
        const { id } = result
        const onClick = () => {
          if (!activeOrthoLayerIds.includes(id)) {
            zoomToOrthoLayer(id)
          }
          toggleOrthoLayerId(id)
        }
        const isActive = activeOrthoLayerIds.includes(id)
        return (
          <div
            onClick={onClick}
            key={result.id}
            style={{ display: 'inline-block' }}
          >
            <OrthoThumbnail
              item={result}
              className={classes(style.image, isActive ? style.active : '')}
            />
          </div>
        )
      }),
      results: imageResults,
    } as TabConfig,
    [SEARCH_CATEGORIES.PUBLICATION]: {
      category: SEARCH_CATEGORIES.PUBLICATION,
      value: 'news' as const,
      tab: renderTab('news', SEARCH_CATEGORIES.PUBLICATION),
      content: timelineCategorized[SEARCH_CATEGORIES.PUBLICATION].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <NewsReferenceCard result={result as PublicationResult} />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.PUBLICATION],
    } as TabConfig,
    [SEARCH_CATEGORIES.SOCIAL_MEDIA]: {
      category: SEARCH_CATEGORIES.SOCIAL_MEDIA,
      value: 'social' as const,
      tab: renderTab('social', SEARCH_CATEGORIES.SOCIAL_MEDIA),
      content: timelineCategorized[SEARCH_CATEGORIES.SOCIAL_MEDIA].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <SocialMediaReferenceCard result={result as SocialMediaResult} />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.SOCIAL_MEDIA],
    } as TabConfig,
    [SEARCH_CATEGORIES.FIRE]: {
      category: SEARCH_CATEGORIES.FIRE,
      value: 'fire' as const,
      tab: renderTab('fire', SEARCH_CATEGORIES.FIRE),
      content: timelineCategorized[SEARCH_CATEGORIES.FIRE].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <FireReferenceCard result={result as FireResult} />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.FIRE],
    } as TabConfig,
    [SEARCH_CATEGORIES.SHIP]: {
      category: SEARCH_CATEGORIES.SHIP,
      value: 'ship' as const,
      tab: renderTab('ship', SEARCH_CATEGORIES.SHIP),
      content: timelineCategorized[SEARCH_CATEGORIES.SHIP].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <ShipReferenceCard result={result as ShipResult} />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.SHIP],
    } as TabConfig,
  } as const

  React.useEffect(() => {
    setActiveTab('images')
  }, [currentQueryId])

  React.useEffect(() => {
    if (hasResults) {
      // Set the active tab based on the first non-empty tab
      const firstNonEmptyTab = TAB_ORDER.map(
        (category: keyof typeof tabs) => tabs[category],
      ).find((tab) => tab.results.length > 0)
      if (firstNonEmptyTab) {
        setActiveTab(firstNonEmptyTab.value)
        setActiveCategory(firstNonEmptyTab.category)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResults])
  const handleTabChange = (value: string | null) =>
    setActiveTab(value ?? 'images')
  return {
    orderedTabs: TAB_ORDER.map((category) => tabs[category]),
    handleTabChange,
    activeCategory,
    activeTab,
  }
}
