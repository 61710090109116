import { getDataMetadata } from '@/api/data-metadata/api.ts'
import type { ImageProvenanceMetadata } from '@/api/data-metadata/types.ts'
import { useQuery } from '@tanstack/react-query'

export function useDataMetadata() {
  return useQuery({
    queryKey: ['DataMetadata'],
    queryFn: () => getDataMetadata(),
    staleTime: 60 * 60 * 1000, // 1 hour in ms
  })
}

export function useImageProvenanceDataMetadata() {
  const emptyProvenance = {} as ImageProvenanceMetadata
  return useDataMetadata().data?.imageProvenance ?? emptyProvenance
}

export function useProviderDataMetadata() {
  return useDataMetadata().data?.providers ?? {}
}

export function useSourceDataMetadata() {
  return useDataMetadata().data?.sources ?? {}
}

export function usePlatformDataMetadata() {
  return useDataMetadata().data?.platform ?? {}
}

export function useSensorDataMetadata() {
  return useDataMetadata().data?.sensors ?? {}
}
